import cmEventController from './cmEventController';
import cmStorage from './cmStorage';
const cmState = {
  settings: {
    get isGodMode() {
      return cmStorage.getValueForKey('isGodMode', false);
    },
    set isGodMode(value) {
      return cmStorage.setValueForKey('isGodMode', value);
    },
    get isDebugMode() {
      return cmStorage.getValueForKey('isDebugMode', false);
    },
    set isDebugMode(value) {
      return cmStorage.setValueForKey('isDebugMode', value);
    },
    get isMusicOn() {
      return cmStorage.getValueForKey('isMusicOn', true);
    },
    set isMusicOn(value) {
      return cmStorage.setValueForKey('isMusicOn', value);
    },
    get isSFXOn() {
      return cmStorage.getValueForKey('isSFXOn', true);
    },
    set isSFXOn(value) {
      return cmStorage.setValueForKey('isSFXOn', value);
    }
  },

  // coins
  get coins() {
    return cmStorage.getValueForKey('coins', 0);
  },
  set coins(value) {
    cmEventController.fire('COINS_CHANGED', value);
    return cmStorage.setValueForKey('coins', value);
  },
  addCoin() {
    this.coins++;
  }
};

window.cmState = cmState;
export default cmState;
