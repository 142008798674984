import Tile from './Tile';
import Block from './Block';
import Monster from './Monster';
import Treasure from './Treasure';
import Graphics from '../Graphics';
import { isNull } from 'lodash';
import phaser from 'phaser';

export default class Map {
  constructor(width, height, scene) {
    this.rooms = []; //dungeon.rooms;
    this.width = width;
    this.height = height;
    this.tiles = [];
    this.blocks = [];

    // var particles = scene.add.particles(Graphics.default.particles.name);
    // particles.setDepth(6);
    // this.emitter = particles.createEmitter({
    //   x: 100,
    //   y: 100,
    //   scale: 0.1,
    //   speed: 100,
    //   lifespan: 500,
    //   blendMode: 'ADD'
    // });
    // this.emitter.stop();
    var roomNumber = 0;
    var firstRoom = isNull;
    this.startingX = scene.mapData.startPosition.x;
    this.startingY = scene.mapData.startPosition.y;

    this.tilemap = scene.make.tilemap({
      tileWidth: Graphics.default.environment.width,
      tileHeight: Graphics.default.environment.height,
      width: width,
      height: height
    });
    var dungeonTiles = this.tilemap.addTilesetImage(
      Graphics.default.environment.name,
      Graphics.default.environment.name,
      Graphics.default.environment.width,
      Graphics.default.environment.height,
      Graphics.default.environment.margin,
      Graphics.default.environment.spacing
    );
    var blockTiles = this.tilemap.addTilesetImage(
      Graphics.default.blocks.name,
      Graphics.default.blocks.name,
      Graphics.default.blocks.width,
      Graphics.default.blocks.height,
      Graphics.default.blocks.margin,
      Graphics.default.blocks.spacing
    );

    // this.emitter.start(true, 4000, null, 10);

    // Tiles Setup
    for (var y = 0; y < height; y++) {
      this.tiles.push([]);
      this.blocks.push([]);
      for (var x = 0; x < width; x++) {
        // var tileType = Tile.default.tileTypeFor(dungeon.tiles[x][y].type);
        var tileType = Tile.default.tileTypeFor(55);
        this.tiles[y][x] = new Tile.default(tileType, x, y, this);

        var blockType = scene.mapData.blocks[x][y];

        if (blockType) {
          this.blocks[y][x] = new Block(
            scene.mapData.blocks[x][y],
            y * 16 + 8,
            x * 16 + 8,
            scene
          );
          //console.log('[', x, ',', y, ']=', blockType)
        }
      }
    }

    // var toReset = [];
    // for (var y = 0; y < height; y++) {
    //     for (var x = 0; x < width; x++) {
    //         var tile = this.tiles[y][x];
    //         if (tile.type === Tile.TileType.Wall && tile.isEnclosed()) {
    //             toReset.push({ y: y, x: x });
    //         }
    //     }
    // }
    // toReset.forEach(function (d) {
    //     this.tiles[d.y][d.x] = new Tile.default(Tile.TileType.None, d.x, d.y, _this);
    // });

    // var roomNumber = Math.floor(Math.random() * dungeon.rooms.length);
    // var firstRoom = dungeon.rooms[roomNumber];
    // this.startingX = Math.floor(firstRoom.x + firstRoom.width / 2);
    // this.startingY = Math.floor(firstRoom.y + firstRoom.height / 2);

    const FLOOR = 7;
    //const DOOR = Graphics.default.environment.indices.doors.horizontal; //129;
    const TREASURE = Graphics.default.environment.indices.treasure.closed;

    var groundLayer = this.tilemap
      .createBlankLayer('Ground', dungeonTiles, 0, 0)
      //.createBlankDynamicLayer("Ground", dungeonTiles, 0, 0)
      .randomize(
        0,
        0,
        this.width,
        this.height,
        Graphics.default.environment.indices.floor.outerCorridor
      );


    this.monsters = [];
    this.treasures = [];

    // RMS TEMP TEST
    // var wy = this.tilemap.tileToWorldXY(
    //   this.startingX,
    //   this.startingY
    // );
    // var m = new Monster(wy.x, wy.y, scene);
    // this.monsters.push(m);

    // RMS TEMP TEST

    for (var i = 0; i < scene.mapData.monsters.length; i++) {
      var worldxy = this.tilemap.tileToWorldXY(
        scene.mapData.monsters[i].position.x,
        scene.mapData.monsters[i].position.y
      );
      //RMS
      // var m = new Monster(worldxy.x, worldxy.y, scene);
      // this.monsters.push(m);
    }

    this.wallLayer = this.tilemap.createBlankLayer('Wall', dungeonTiles, 0, 0);
    this.doorLayer = this.tilemap.createBlankLayer('Door', dungeonTiles, 0, 0);
    this.treasureLayer = this.tilemap.createBlankLayer(
      'Treasure',
      dungeonTiles,
      0,
      0
    );
    this.blockLayer = this.tilemap.createBlankLayer('Blocks', blockTiles, 0, 0);

    var collidableDoors = [
      Graphics.default.environment.indices.doors.horizontal,
      Graphics.default.environment.indices.doors.vertical,
      Graphics.default.environment.indices.stairs.down,
      Graphics.default.environment.indices.stairs.up
    ];
    var collidableStairs = [];

    for (var x = 0; x < width; x++) {
      for (var y = 0; y < height; y++) {
        // if (scene.mapData.blocks[x][y] && (Math.abs(this.startingX - x) > 1 || (Math.abs(this.startingY - y) > 1))) {
        //   let b = (scene.mapData.blocks[x][y]) * 16;
        //   let btile = this.blockLayer.putTileAt(b, x, y);
        //   btile.properties = { colorId: scene.mapData.blocks[x][y] - 1 };
        // }
        if (
          this.blocks[y][x] &&
          (Math.abs(this.startingX - x) > 1 || Math.abs(this.startingY - y) > 1)
        ) {
          let b = this.blocks[y][x].type * 16; // Number of items per row in block image
          let btile = this.blockLayer.putTileAt(b, x, y);
          btile.properties = { colorId: this.blocks[y][x].type - 1 };
        }
        // console.log(btile)
        // let bl = this.blockLayer.tileAt(x, y);
        // bl.properties.blocked = b;

        let t = scene.mapData.tiles[x][y];

        if (collidableDoors.includes(t)) {
          this.doorLayer.putTileAt(t, x, y);
        } else if (collidableStairs.includes(t)) {
          this.doorLayer.putTileAt(t, x, y);
        } else if (t === TREASURE) {
          this.treasureLayer.putTileAt(t, x, y);
          // var tr = new Treasure(x, y, scene);
          // this.treasures.push(tr);

        } else if (t === FLOOR) {
          // this.groundLayer.putTileAt(t, x, y);
        } else {
          this.wallLayer.putTileAt(t, x, y);
        }
      }
    }
    this.wallLayer.setCollisionBetween(0, 0x7f);

    this.doorLayer.setCollision(collidableDoors);
    this.doorLayer.setTileIndexCallback(
      collidableDoors,
      function (_, tile) {
        if (
          tile.index ===
          Graphics.default.environment.indices.doors.horizontal ||
          tile.index === Graphics.default.environment.indices.doors.vertical
        ) {
          this.doorLayer.putTileAt(
            Graphics.default.environment.indices.doors.destroyed,
            tile.x,
            tile.y
          );
          this.tileAt(tile.x, tile.y).open();
          //scene.fov.recalculate();
        } else if (
          tile.index === Graphics.default.environment.indices.stairs.down
        ) {
          console.log("PREV")
          console.log(_)
          if (_.name === "Player") {
            scene.loadPrevLevel();
          }
        } else if (
          tile.index === Graphics.default.environment.indices.stairs.up
        ) {
          console.log("NEXT")
          if (_.name === "Player") {
            scene.loadNextLevel();
          }
        }
      },
      this
    );

    var collidableTreasure = [
      Graphics.default.environment.indices.treasure.closed,
      Graphics.default.environment.indices.treasure.destroyed
    ];
    this.treasureLayer.setCollision(collidableTreasure);
    this.treasureLayer.setTileIndexCallback(
      collidableTreasure,
      function (_, tile) {
        console.log("treasure collide")
        this.treasureLayer.putTileAt(
          Graphics.default.environment.indices.treasure.destroyed,
          tile.x,
          tile.y
        );
        //this.tileAt(tile.x, tile.y).open();
        //scene.fov.recalculate();
      },
      this
    );

    var collidableBlocks = [16, 32, 48, 64];
    this.blockLayer.setCollision(collidableBlocks);
    // this.blockLayer.setCollisionBetween(0, 0x7f);
    this.blockLayer.setTileIndexCallback(
      collidableBlocks,
      function (_, tile) {
        // scene.fov.recalculate();
      },
      this
    );
    // this.doorLayer.setDepth(3);
    // this.wallLayer = this.tilemap.convertLayerToStatic(wallLayer);
    // this.wallLayer.setDepth(2);
  }

  tileAt(x, y) {
    if (y < 0 || y >= this.height || x < 0 || x >= this.width) {
      return null;
    }
    return this.tiles[y][x];
  }

  blockAt(x, y) {
    if (y < 0 || y >= this.height || x < 0 || x >= this.width) {
      return null;
    }
    return this.blocks[y][x];
  }

  // Map.prototype.blockAt = function (x, y) {
  //     if (y < 0 || y >= this.height || x < 0 || x >= this.width) {
  //         return null;
  //     }
  //     return this.tiles[y][x];
  // };
  destroyBlockList(affectedTiles, colorId) {
    for (var i = 0; i < affectedTiles.length; ++i) {
      let pos = affectedTiles[i];

      for (var j = 0; j < this.monsters.length; ++j) {
        if (this.monsters[j] && this.tilemap) {
          let x = this.tilemap.worldToTileX(this.monsters[j].spine.x);
          let y = this.tilemap.worldToTileY(this.monsters[j].spine.y);

          if (
            pos[0] === x &&
            pos[1] === y &&
            this.monsters[j].color === colorId
          ) {
            this.monsters[j].kill();
          }
        }
      }

      let blocktile = this.blockLayer.getTileAt(pos[0], pos[1]);

      if (blocktile && blocktile.properties.colorId === colorId) {
        // console.log("======",colorId,"=========");
        // console.log(blocktile);
        let b = this.blockLayer.removeTileAt(pos[0], pos[1]);

        // this.blocks[pos[0]][pos[1]].kill();
        let bl = this.blocks[pos[0]][pos[1]];
        if (bl) {
          bl.kill(colorId);
          this.doorLayer.scene.player.addColorCube(colorId, 1, this.doorLayer.scene.gui);
        }
      }
    }
  }

  withinRoom(x, y) {
    return (
      this.rooms.find(function (r) {
        var _a = r.getBoundingBox(),
          top = _a.top,
          left = _a.left,
          right = _a.right,
          bottom = _a.bottom;
        return (
          y >= top - 1 && y <= bottom + 1 && x >= left - 1 && x <= right + 1
        );
      }) != undefined
    );
  }
}
