import phaser from 'phaser';
import Graphics from '../Graphics';

var speed = 60;
var attackSpeed = 500;
var attackDuration = 165;
var staggerDuration = 200;
var staggerSpeed = 100;
var attackCooldown = attackDuration * 2;

function setAttachmentsOnValue(player, value, attachements) {
  // console.log("setAttachmentsOnValue: ", value, attachements, typeof value)
  if (player && player.spine) {
    for (let i = 0; i < attachements.length; ++i) {
      let atype = attachements[i] + '_' + (value + 1);
      player.spine.setAttachment(attachements[i], atype)
    }
    return value;
  }
}

export default class Player {
  spine = null;
  attackAnim = '';
  moveAnim = '';
  configurations = [
    {
      label: 'plAnim',
      maxVal: 20,
      func: function (scene, player, value) {
        if (player && player.spine) {
          // console.log(scene.outfit)
          let anim = player.spine.getAnimationList()[value];
          // console.log("Animation set: ", anim)
          player.spine.play(anim, true);
          // console.log(player.spine)
          // player.spine.setAttachment('head', 'head_5')
          //player.spine.setAttachment('waist', 'head_1')
        }
        // let skins = player.spine.getSkinList();
        // if (value >= skins.length - 1) {
        //   value = value % (skins.length - 1);
        //   console.log("Max skins exceeded:", skins.length)
        // }
        // player.spine.setSkinByName(skins[value + 1]);
        return value;
      }
    },
    {
      label: 'plHead',
      maxVal: 37,
      func: function (scene, player, value) {
        return setAttachmentsOnValue(player, value, ['head']);
      }
    },
    {
      label: 'plHands',
      maxVal: 18,
      func: function (scene, player, value) {
        return setAttachmentsOnValue(player, value, ['hand_near', 'hand_far']);
      }
    },
    {
      label: 'plLower Arms',
      maxVal: 18,
      func: function (scene, player, value) {
        return setAttachmentsOnValue(player, value, ['arm_lower_near', 'arm_lower_far']);
      }
    },
    {
      label: 'plUpper Arms',
      maxVal: 18,
      func: function (scene, player, value) {
        return setAttachmentsOnValue(player, value, ['arm_upper_near', 'arm_upper_far']);
      }
    },
    {
      label: 'plTorso',
      maxVal: 18,
      func: function (scene, player, value) {
        return setAttachmentsOnValue(player, value, ['torso']);
      }
    },
    {
      label: 'plWaist',
      maxVal: 18,
      func: function (scene, player, value) {
        return setAttachmentsOnValue(player, value, ['waist']);
      }
    },
    {
      label: 'plNeck',
      maxVal: 18,
      func: function (scene, player, value) {
        return setAttachmentsOnValue(player, value, ['neck']);
      }
    },
    {
      label: 'plLowerLeg',
      maxVal: 18,
      func: function (scene, player, value) {
        return setAttachmentsOnValue(player, value, ['leg_lower_near', 'leg_lower_far']);
      }
    },
    {
      label: 'plUpperLeg',
      maxVal: 18,
      func: function (scene, player, value) {
        return setAttachmentsOnValue(player, value, ['leg_upper_near', 'leg_upper_far']);
      }
    },
    // {
    //   label: 'Hair Color',
    //   maxVal: 32,
    //   func: function (scene, player, value) {
    //     return value;
    //   }
    // },
    // {
    //   label: 'Suit Type',
    //   maxVal: 16,
    //   func: function (scene, player, value) {
    //     return value;
    //   }
    // },
    {
      label: 'plWaistColor',
      maxVal: 4095,
      func: function (scene, player, value) {
        let c = 4095 - value;
        let r = (c & 0x0f00) * 0x01000;
        let g = (c & 0x00f0) * 0x0100;
        let b = (c & 0x000f) * 0x010;
        let o = r + g + b;
        // player.spine.tint = o;
        setAttachmentsOnValue(player, 0, ['waist']);
        player.spine.setColor(o, player.spine.getSlotList()[6]);
        return value;
      }
    },
    {
      label: 'plShoeColor',
      maxVal: 4095,
      func: function (scene, player, value) {
        let c = value; //4095 - value;
        let r = (c & 0x0f00) * 0x01000;
        let g = (c & 0x00f0) * 0x0100;
        let b = (c & 0x000f) * 0x010;
        let o = r + g + b;
        // player.spine.tint = o;
        player.spine.setColor(o, player.spine.getSlotList()[5]);
        player.spine.setColor(o, player.spine.getSlotList()[12]);
        return value;
      }
    },
    {
      label: 'plBeamStrength',
      maxVal: 256,
      func: function (scene, player, value) {
        return value;
      }
    },
    {
      label: 'plBeamSpeed',
      maxVal: 256,
      func: function (scene, player, value) {
        return value;
      }
    },
    {
      label: 'plBeamLength',
      maxVal: 64,
      func: function (scene, player, value) {
        return value;
      }
    },
    {
      label: 'plBeamWidth',
      maxVal: 64,
      func: function (scene, player, value) {
        return value;
      }
    },
    {
      label: 'plBatteryLife',
      maxVal: 32,
      func: function (scene, player, value) {
        return value;
      }
    },
    {
      label: 'plPlayerSpeed',
      maxVal: 64,
      func: function (scene, player, value) {
        return value;
      }
    }
  ];

  constructor(x, y, scene) {
    this.scene = scene;

    this.sprite = scene.physics.add.sprite(
      x,
      y,
      Graphics.default.player.name,
      0
    );
    this.sprite.setSize(80, 80);
    // this.sprite.setOffset(200, 280);

    // this.sprite.setOffset(64, 64);
    this.sprite.scale = .1;
    this.sprite.setDepth(5);
    this.sprite.setVisible(Graphics.default.player.visible);

    this.spine = scene.add.spine(x, y, 'hero', 'walk', true).setScale(Graphics.default.player.spine.scale, Graphics.default.player.spine.scale);

    let skins = this.spine.getSkinList();
    let rnd = Math.floor(Math.random() * (skins.length - 1)) + 1;
    this.spine.setSkinByName(skins[rnd]);
    //scene.physics.add.existing(this.spine); - cause crash with customized chars

    window.sprite = this.sprite;
    window.spine = this.spine;
    this.spine.name = "Player"

    this.spine.displayOriginY = 640;
    // this.sprite.setOffset(200, 280);
    // this.sprite.scale = .1;
    // // this.sprite.tint = 0x0000;
    // this.sprite.anims.play(Graphics.default.player.animations.idle.key);
    this.facingUp = false;
    // this.sprite.setDepth(5);

    this.keys = scene.input.keyboard.addKeys({
      up: phaser.Input.Keyboard.KeyCodes.UP,
      down: phaser.Input.Keyboard.KeyCodes.DOWN,
      left: phaser.Input.Keyboard.KeyCodes.LEFT,
      right: phaser.Input.Keyboard.KeyCodes.RIGHT,
      // space: phaser.Input.Keyboard.KeyCodes.SPACE,
      shift: phaser.Input.Keyboard.KeyCodes.SHIFT,
      w: 'w',
      a: 'a',
      s: 's',
      d: 'd'
      // dncolor: phaser.Input.Keyboard.KeyCodes.COMMA,
      // upcolor: phaser.Input.Keyboard.KeyCodes.PERIOD,
    });

    this.collection = {
      colorcubes: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      coins: 0
    };

    this.attackUntil = 0;
    this.attackLockedUntil = 0;
    this.attacking = false;
    this.staggerUntil = 0;
    this.staggered = false;
    var particles = scene.add.particles('Player');//Graphics.default.player.name);
    particles.setDepth(6);
    this.emitter = particles.createEmitter({
      alpha: { start: 0.7, end: 0, ease: 'Cubic.easeOut' },
      // follow: this.sprite,
      follow: this.spine,
      quantity: 1,
      lifespan: 200,
      blendMode: phaser.BlendModes.ADD,
      scaleX: () => {
        // return this.sprite.flipX ? -1 : 1;
        return this.spine.flipX ? -1 : 1;
      },
      emitCallback: (particle) => {
        particle.frame = this.sprite.frame;
      }
    });
    this.emitter.stop();
    this.flashEmitter = particles.createEmitter({
      alpha: { start: 0.5, end: 0, ease: 'Cubic.easeOut' },
      follow: this.sprite,
      // follow: this.spine,
      quantity: 1,
      lifespan: 100,
      scaleX: () => {
        return this.sprite.flipX ? -1 : 1;
        // return this.spine.flipX ? -1 : 1;
      },
      emitCallback: (particle) => {
        particle.frame = this.sprite.frame;
        // particle.frame = this.spine.frame;
      }
    });
    this.flashEmitter.stop();
    this.body = this.sprite.body;

    // this.body = this.spine.body;
    //RMS
    // this.body.setOffset(this.spine.width / 4, this.spine.height * 3 / 4);
    // this.body.setSize(this.spine.width / 4, this.spine.height / 4);

    // console.log(this.spine.getAnimationList())
    // console.log(this.spine.body.width)
    // console.log(this.spine.body.height)
    // 0: "aim"
    // 1: "death"
    // 2: "hoverboard"
    // 3: "idle"
    // 4: "jump"
    // 5: "portal"
    // 6: "run"
    // 7: "shoot"
    // 8: "walk"
    //this.spine.play(this.spine.getAnimationList()[0], true);
    // this.spine.play('idle', true);

    for (let i = 0; i < this.configurations.length; i++) {
      const label = this.configurations[i].label;
      let val = Number(cmStorage.getValueForKey(label, 0));
      this.configurations[i].func(this.scene, this, val);
    }
    this.time = 0;
  }

  // define the setFlipX function
  setFlipDir = function (flip) {
    if (flip) {
      // this.body.setOffset(this.spine.width * 3 / 4, this.spine.height * 3 / 4);
      // this.body.setSize(this.spine.width / 4, this.spine.height / 4);
      this.spine.setScale(-Graphics.default.player.spine.scale, Graphics.default.player.spine.scale);
      // // this.spine.setScale(-1, 1)
    } else {
      // this.body.setOffset(this.spine.width / 4, this.spine.height * 3 / 4);
      // this.body.setSize(this.spine.width / 4, this.spine.height / 4);
      this.spine.setScale(Graphics.default.player.spine.scale, Graphics.default.player.spine.scale);
      // // this.spine.setScale(1, 1)
    }
  }

  isAttacking() {
    return this.attacking;
  }

  addColorCube(nColorId, nCount, gui) {
    let cnt = this.collection.colorcubes[nColorId] += nCount;

    if (gui) {
      gui.updateColorCollectors(nColorId, cnt);
    }
    return cnt;
  }

  addCoins(nCount) {
    return this.collection.coins += nCount;
  }

  stagger() {
    if (this.time > this.staggerUntil) {
      this.staggered = true;
      // TODO
      this.scene.cameras.main.shake(150, 0.001);
      this.scene.cameras.main.flash(50, 100, 0, 0);
    }
  }

  setPlayerFeature(lable, value) {

  }

  update(time) {
    this.time = time;
    var keys = this.keys;

    var lastMove = this.moveAnim;
    var lastAttack = this.attackAnim;

    const {
      left: joyLeft,
      right: joyRight,
      up: joyUp,
      down: joyDown
    } = this.scene.joystick;

    if (this.staggered && !this.body.touching.none) {
      this.staggerUntil = this.time + staggerDuration;
      this.staggered = false;
      this.body.setVelocity(0);
      if (this.body.touching.down) {
        this.body.setVelocityY(-staggerSpeed);
      } else if (this.body.touching.up) {
        this.body.setVelocityY(staggerSpeed);
      } else if (this.body.touching.left) {
        this.body.setVelocityX(staggerSpeed);
        this.setFlipDir(true);
      } else if (this.body.touching.right) {
        this.body.setVelocityX(-staggerSpeed);

        this.setFlipDir(false);
      }
      // this.sprite.anims.play(Graphics.default.player.animations.stagger.key);
      // if (lastMove != )
      this.spine.play(Graphics.default.player.animations.stagger.key, false);
      this.flashEmitter.start();
      // this.sprite.setBlendMode(Phaser.BlendModes.MULTIPLY);
    }
    if (time < this.attackUntil || time < this.staggerUntil) {
      return;
    }
    this.body.setVelocity(0);
    var left = keys.left.isDown || keys.a.isDown || joyLeft;
    var right = keys.right.isDown || keys.d.isDown || joyRight;
    var up = keys.up.isDown || keys.w.isDown || joyUp;
    var down = keys.down.isDown || keys.s.isDown || joyDown;
    if (!this.body.blocked.left && left) {
      this.body.setVelocityX(-speed);
      this.setFlipDir(true);
    } else if (!this.body.blocked.right && right) {
      this.body.setVelocityX(speed);
      this.setFlipDir(false);
    }
    if (!this.body.blocked.up && up) {
      this.body.setVelocityY(-speed);
    } else if (!this.body.blocked.down && down) {
      this.body.setVelocityY(speed);
    }
    if (left || right) {
      this.moveAnim = Graphics.default.player.animations.walk.key;
      this.attackAnim = Graphics.default.player.animations.slash.key;
      this.facingUp = false;
    } else if (down) {
      this.moveAnim = Graphics.default.player.animations.walk.key;
      this.attackAnim = Graphics.default.player.animations.slashDown.key;
      this.facingUp = false;
    } else if (up) {
      this.moveAnim = Graphics.default.player.animations.walkBack.key;
      this.attackAnim = Graphics.default.player.animations.slashUp.key;
      this.facingUp = true;
    } else if (this.facingUp) {
      this.moveAnim = Graphics.default.player.animations.idleBack.key;
    } else {
      this.moveAnim = Graphics.default.player.animations.idle.key;
    }

    if (
      // (keys.space.isDown ||
      (keys.shift.isDown ||
        this.scene.gui.attackIsPressed) &&
      time > this.attackLockedUntil &&
      this.body.velocity.length() > 0
    ) {
      this.attackUntil = time + attackDuration;
      this.attackLockedUntil = time + attackDuration + attackCooldown;
      this.body.velocity.normalize().scale(attackSpeed);
      // this.sprite.anims.play(attackAnim, true);
      if (!this.attacking) {
        this.spine.play(this.attackAnim, true);
        this.emitter.start();
      }
      // this.sprite.setBlendMode(phaser.BlendModes.ADD);
      // this.spine.setBlendMode(phaser.BlendModes.ADD);
      this.attacking = true;
      return;
    }
    this.attacking = false;
    // this.sprite.anims.play(moveAnim, true);
    // console.log(this.spine.state.getCurrent(0).animation.name)

    if (lastMove != this.moveAnim) {
      this.spine.play(this.moveAnim, true);
    }
    this.body.velocity.normalize().scale(speed);
    // this.sprite.setBlendMode(phaser.BlendModes.NORMAL);
    // this.spine.setBlendMode(phaser.BlendModes.NORMAL);

    this.spine.x = this.sprite.x;
    this.spine.y = this.sprite.y;

    if (this.emitter.on) {
      this.emitter.stop();
    }
    if (this.flashEmitter.on) {
      this.flashEmitter.stop();
    }
  }
}
