//TODO - integrate
// SWIPE_UP: 'SWIPE_UP',
// SWIPE_DOWN: 'SWIPE_DOWN',
// SWIPE_LEFT: 'SWIPE_LEFT',
// SWIPE_RIGHT: 'SWIPE_RIGHT',
// TOUCH_MOVE: 'TOUCH_MOVE',

import cmEventController from './cmEventController';

const cmGestureManager = {
  swipeDirection: null,
  idPointerdown: false,
  isDragging: false,
  startPos: null,
  endPos: null,
  duration: 0,
  touchDurationThreshold: 111,
  startTime: 0,
  update(input) {
    const pointer = input.activePointer;
    this.isDragging = pointer.isDown;
    if (this.isDragging && !this.isPointerDown) {
      this.isPointerDown = true;
      this.startPos = new Phaser.Math.Vector2(pointer.downX, pointer.downY);
      this.startTime = pointer.time;
      cmEventController.fire(new CustomEvent(cmEventController.TOUCH_START), {
        pos: this.startPos
      });
    } else if (!this.isDragging && this.isPointerDown) {
      this.isPointerDown = false;
      this.endPos = new Phaser.Math.Vector2(pointer.upX, pointer.upY);
      cmEventController.fire(new CustomEvent(cmEventController.TOUCH_END), {
        pos: this.endPos
      });

      const duration = pointer.time - this.startTime;
      if (duration <= this.touchDurationThreshold) {
        cmEventController.fire(new CustomEvent(cmEventController.TOUCH), {
          pos: this.endPos
        });
      }

      // TODO emit Swipe gesture events
      const start = this.startPos;
      const end = this.endPos;

      const swipeThreshold = 100;
      // console.log(start, end);
      if (start.x - swipeThreshold < end.x) {
        // console.log('swipe right');
      } else if (start.x + swipeThreshold > end.x) {
        // console.log('swipe left');
      }
    } else if (this.isDragging && this.isPointerDown) {
    }
  }
};
export default cmGestureManager;
