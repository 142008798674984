import { getScaleFactor } from './utils';
import cmStorage from './cmStorage';
import cmEvents from '../utils/cmEventController';
import cmAudioController from './cmAudioController';
import cmState from './cmState';

const urls = [
  'www.google.com',
  'www.amazon.com',
  'www.youtube.com',
  'www.yahoo.com',
  'www.facebook.com',
  'www.reddit.com',
  'www.wikipedia.com',
  'www.ebay.com',
  'www.bing.com',
  'www.netflix.com',
  'www.offic.com',
  'www.shopify.com',
  'www.twitch.com',
  'www.cnn.com',
  'www.linkedin.com',
  'www.instagram.com',
  'www.nytimes.com',
  'www.chase.com'
];

const dungeonListView = document.querySelector('.cmDungeonListView');
const settingsView = document.querySelector('.cmSettingsView');
const chatView = document.querySelector('.cmChatView');
const mapView = document.querySelector('.cmMapView');
const coinTextEL = document.querySelector('.cmCoin>h3');

// max count for block mining
const maxCount = 30;

const cmUIController = {
  hideEl(el) {
    el.style.visibility = 'hidden';
  },
  showEl(el) {
    if (!el.isEqualNode(chatView)) {
      this.closeAllBut(el);
    }
    el.style.visibility = 'visible';
  },
  isVisible(el) {
    return el.style.visibility === 'visible';
  },
  toggleEl(el) {
    this.isVisible(el) ? this.hideEl(el) : this.showEl(el);
  },
  closeAllBut(el) {
    [dungeonListView, settingsView, mapView]
      .filter((n) => !n.isEqualNode(el))
      .forEach((n) => this.hideEl(n));
  },

  // Dungeon Url List
  openDungeonList(callback) {
    this.closeAllBut(dungeonListView);
    this.showEl(dungeonListView);

    if (!dungeonListView.hasCloseListener) {
      dungeonListView.hasCloseListener = true;
      dungeonListView
        .querySelector('.cmCloseButton')
        .addEventListener('click', () => {
          cmAudioController.click();
          this.hideEl(dungeonListView);
        });
    }

    const elDungeonList = document.querySelector('.cmDungeonList');
    // remove all children
    elDungeonList.innerHTML = '';

    for (let i = 0; i < urls.length; i++) {
      const url = urls[i];
      const div = document.createElement('div');
      div.classList.add('cmDungeonListItem');
      div.innerHTML = `<h4>${url}</h4>`;
      div.onclick = callback
        ? () => {
            cmAudioController.click();
            callback(url);
          }
        : null;
      elDungeonList.appendChild(div);
    }
  },
  closeDungeonList() {
    const cmDungeonListView = document.querySelector('.cmDungeonListView');
    this.hideEl(cmDungeonListView);
  },
  toggleDungeonList() {
    this.toggleEl(dungeonListView);
  },

  // settings view
  openSettings() {
    this.showEl(settingsView);
    this.closeAllBut(settingsView);

    if (!settingsView.hasCloseListener) {
      settingsView.hasCloseListener = true;
      settingsView
        .querySelector('.cmCloseButton')
        .addEventListener('click', () => {
          cmAudioController.click();
          this.hideEl(settingsView);
        });
    }

    const container = document.querySelector('.cmSettingsContent');
    container.innerHTML = '';

    const formContent = [
      `isMoble: ${window.isMobile}`,
      `gui scale factor: ${getScaleFactor().toFixed(3)}`,
      `phaser canvas w/h: ${game.canvas.width}x${game.canvas.height}`
    ];
    for (let i = 0; i < formContent.length; i++) {
      const item = formContent[i];
      const div = document.createElement('div');
      div.classList.add('cmSettingsContentItem');
      div.innerHTML = item;
      // div.onclick = callback
      //   ? () => {
      //       callback(url);
      //     }
      //   : null;
      container.appendChild(div);
    }

    const makeToggle = (name, onChange) => {
      const div = document.createElement('div');
      div.classList.add('cmSettingsContentItem');

      const label = document.createElement('label');
      label.textContent = name;

      const input = document.createElement('input');
      input.type = 'checkbox';
      input.name = name;
      input.checked = cmStorage.getValueForKey(name, false);
      input.onchange = onChange || null;

      div.appendChild(input);
      div.appendChild(label);

      return div;
    };

    // Toggles - add them here.
    [
      makeToggle('isGodMode', (e) => {
        const name = e.target.name;
        const on = e.target.checked;
        cmStorage.setValueForKey(name, on);
        console.log(name, cmStorage.getValueForKey(name));
      }),
      makeToggle('isDebugMode', (e) => {
        const name = e.target.name;
        const on = e.target.checked;
        cmStorage.setValueForKey(name, on);
        console.log(name, cmStorage.getValueForKey(name));
      }),
      makeToggle('isMusicOn', (e) => {
        const name = e.target.name;
        const on = e.target.checked;
        cmStorage.setValueForKey(name, on);
        console.log(name, cmStorage.getValueForKey(name));
      }),
      makeToggle('isSFXOn', (e) => {
        const name = e.target.name;
        const on = e.target.checked;
        cmStorage.setValueForKey(name, on);
        console.log(name, cmStorage.getValueForKey(name));
      })
    ].map((n) => container.appendChild(n));
  },
  closeSettings() {
    this.hideEl(settingsView);
  },
  toggleSettings() {
    if (this.isVisible(settingsView)) {
      this.closeSettings();
    } else {
      this.openSettings();
    }
  },

  // chat view
  openChat() {
    this.showEl(chatView);
    if (!chatView.hasCloseListener) {
      chatView.hasCloseListener = true;
      chatView.querySelector('.cmCloseButton').addEventListener('click', () => {
        cmAudioController.click();
        this.hideEl(chatView);
      });
    }
  },
  closeChat() {
    this.hideEl(chatView);
  },
  toggleChat() {
    if (this.isVisible(chatView)) {
      this.closeChat();
    } else {
      this.openChat();
    }
  },

  // map view
  openMap() {
    this.showEl(mapView);
    this.closeAllBut(mapView);
    if (!mapView.hasCloseListener) {
      mapView.hasCloseListener = true;
      mapView.querySelector('.cmCloseButton').addEventListener('click', () => {
        cmAudioController.click();
        this.hideEl(mapView);
      });
    }
  },
  closeMap() {
    this.hideEl(mapView);
  },
  toggleMap() {
    if (this.isVisible(mapView)) {
      this.closeMap();
    } else {
      this.openMap();
    }
  },

  // character view
  openCharacterView() {
    cmEvents.fire(cmEvents.OPEN_CHARACTER_VIEW);
  },
  closeCharacterView() {
    cmEvents.fire(cmEvents.CLOSE_CHARACTER_VIEW);
  },
  toggleCharacterView() {
    cmEvents.fire(cmEvents.TOGGLE_CHARACTER_VIEW);
  },

  // Dungeon details (just below the header)
  setDungeonLevel(value) {
    try {
      document.querySelector('.cmDungeonLevel>span').innerHTML = value;
    } catch (e) {
      // console.warn(e);
    }
  },
  setDungeonName(value) {
    try {
      document.querySelector('.cmDungeonName>span').innerHTML = value;
    } catch (e) {
      // console.warn(e);
    }
  },

  // Unit player frame in upper left. Displays health and battery life
  unitFrameBatteryLevelWidth: null,
  // expects value between 0 and 1
  setUnitFrameBatteryLevel(value) {
    const bar = document.querySelector('.cmBatteryBar');
    const txt = bar.querySelector('h4');
    if (!this.unitFrameBatteryLevelWidth) {
      this.unitFrameBatteryLevelWidth = bar.offsetWidth;
    }
    const units = this.unitFrameBatteryLevelWidth / 100;
    txt.textContent = value * 100;
    bar.style.width = value * 100 * units + 'px';
  },

  unitFrameHealthLevelWidth: null,
  // expects value between 0 and 1
  setUnitFrameHealthLevel(value) {
    const bar = document.querySelector('.cmHealthBar');
    const txt = bar.querySelector('h4');
    if (!this.unitFrameHealthLevelWidth) {
      this.unitFrameHealthLevelWidth = bar.offsetWidth;
    }
    const units = this.unitFrameHealthLevelWidth / 100;
    txt.textContent = value * 100;
    bar.style.width = value * 100 * units + 'px';
  },
  addClickListenerToPreloader() {
    const preloadScreen = document.querySelector('.cmPreloaderView');
    preloadScreen.addEventListener('click', () => {
      preloadScreen.parentNode.removeChild(preloadScreen);
      document.querySelector('.cmGUI').style.display = 'flex';
    });
  },

  orbCacheByColor: [],
  makeColorOrb(color) {
    // prevent duplicate orbs after game reloads
    if (this.orbCacheByColor.indexOf(color) > -1) return;

    this.orbCacheByColor.push(color);

    const parent = document.querySelector('.cmColorOrbContainer');
    const colorBarTemplate = document.querySelector('#cmColorOrbTemplate');
    const colorBar = colorBarTemplate.content.cloneNode(true);

    const tPcent = colorBar.querySelector('h2');
    const fill = colorBar.querySelector('.cmColorOrbFill');
    const height = 100; // fill.offsetHeight;
    const units = height / maxCount;

    const white = '#ffffff'; // id = 0
    const red = '#ff0000'; // id = 1
    const green = '#00ff00'; // id = 2
    const _color = {
      0xeeeeee: white,
      0xee0000: red,
      0x00ee00: green
    }[color];

    fill.style.backgroundColor = _color;
    parent.appendChild(colorBar);

    return {
      parent,
      cssColor: _color,
      text: tPcent,
      fill,
      height,
      update() {
        let count = parseInt(
          cmStorage.getValueForKey(`pcent-mined-${_color}`, 0)
        );
        if (count >= maxCount - 1) {
          count = 0;
          cmEvents.fire(cmEvents.COLOR_ORB_MAX_REACHED, this);
        } else {
          count++;
          cmEvents.fire(cmEvents.COLOR_ORB_INCREASE, this);
        }
        cmStorage.setValueForKey(`pcent-mined-${_color}`, count);

        const pcent = units * count;
        this.text.innerText = Math.floor(pcent).toString() + '%';
        fill.style.transform = `scaleY(${pcent * 0.01})`;
      }
    };
  },
  hydrate() {
    coinTextEL.innerHTML = cmState.coins;
  },
  updateCoinValue({ detail }) {
    coinTextEL.innerHTML = detail;
  }
};

export default cmUIController;
window.cmUIController = cmUIController;
