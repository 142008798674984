import phaser, { Scene } from 'phaser';
import Graphics from '../Graphics';
import FOVLayer from '../entities/FOVLayer';
import Player from '../entities/Player';
import Beam from '../entities/Beam';
import Map from '../entities/Map';
import { getRoom } from '../entities/MapApis';
import cmEvents from '../utils/cmEventController';
import cmUIController from '../utils/cmUIController';

var worldTileHeight = Graphics.default.environment.worldTileHeight;
var worldTileWidth = Graphics.default.environment.worldTileWidth;

export default class DungeonScene extends Scene {
  constructor() {
    super({ key: 'DungeonScene' });
    this.lastX = -1;
    this.lastY = -1;
    this.player = null;
    this.fov = null;
    this.tilemap = null;
    this.monsters = [];
    this.monsterGroup = null;
    this.mapData = null;
    this.joystick = null;
    this.gui = null;
    this.dungeonUrl = null;
    this.dungeonLevel = 1;
    this.dungeonLoading = false;
  }

  // this function gets called with user params when the Lobby Scene loads this scene.
  init({ dungeonUrl, dungeonLevel }) {
    // console.log("INIT Level - ", dungeonUrl, dungeonLevel)
    this.dungeonUrl = dungeonUrl;
    this.dungeonLevel = dungeonLevel ?? 1;
  }

  preload() { }
  monsterPlayerCollide(_, monsterSprite) {
    //   console.log('monsterPlayerCollide');
    var monster = this.monsters.find((s) => {
      // return s.sprite === monsterSprite;
      return s.spine === monsterSprite;
    });
    if (!monster) {
      console.log('Missing monster for sprite collision!');
      console.log(this.monsters)
      return;
    }
    if (this.player.isAttacking()) {
      this.monsters = this.monsters.filter((s) => {
        return s != monster;
      });
      monster.kill();
      return false;
    } else {
      this.player.stagger();
      return true;
    }
  }
  create = async () => {
    this.loading = true;
    document.addEventListener(cmEvents.MENU_BUTTON_CLICKED, (e) => {
      const { name: buttonName } = e.detail;
      // load lobby scene
      if (buttonName === 'cmMenuBtn') {
        this.scene.stop('InfoScene');
        this.scene.stop('Joystick');
        this.scene.stop('GUI');
        this.scene.start('Lobby');
      }
    });
    if (worldTileHeight % 2 === 0 || worldTileWidth % 2 === 0) {
      console.error(
        'worldTileHeight and worldTileWidth must be odd numbers, or the dungeon wont render correctly.'
      );
      return;
    }

    cmUIController.setDungeonLevel(this.dungeonLevel);
    cmUIController.setDungeonName(this.dungeonUrl);

    // close character screen if it's open
    cmEvents.fire(cmEvents.CLOSE_CHARACTER_VIEW);

    const { data } = await getRoom(
      this.dungeonUrl || 'www.google.com',
      '0.1.3',
      this.dungeonLevel,
      worldTileWidth,
      worldTileHeight,
      1
    );
    this.mapData = data;

    this.events.on('wake', () => {
      this.scene.run('InfoScene');
    });

    // add player anims
    // Object.values(Graphics.default.player.animations).forEach((anim) => {
    //   this.anims.create({
    //     ...anim,
    //     frames: this.anims.generateFrameNumbers(
    //       Graphics.default.player.name,
    //       anim.frames
    //     )
    //   });
    // });

    // add head anims
    // Object.values(Graphics.default.head.animations).forEach((anim) => {
    //   this.anims.create({
    //     ...anim,
    //     frames: this.anims.generateFrameNumbers(
    //       Graphics.default.head.name,
    //       anim.frames
    //     )
    //   });
    // });

    // add monster anims
    // Object.values(Graphics.default.monster.animations).forEach((anim) => {
    //   this.anims.create({
    //     ...anim,
    //     frames: this.anims.generateFrameNumbers(
    //       Graphics.default.monster.name,
    //       anim.frames
    //     )
    //   });
    // });

    var map = new Map(worldTileWidth, worldTileHeight, this);
    this.tilemap = map.tilemap;
    this.fov = new FOVLayer(map);
    this.player = new Player(
      this.tilemap.tileToWorldX(map.startingX),
      this.tilemap.tileToWorldY(map.startingY),
      this
    );

    this.beam = new Beam(
      map,
      this.tilemap.tileToWorldX(map.startingX),
      this.tilemap.tileToWorldY(map.startingY),
      this
    );

    this.monsters = map.monsters;
    this.monsterGroup = this.physics.add.group(
      this.monsters.map((s) => {
        return s.spine;
      })
    );

    this.cameras.main.setRoundPixels(true);
    this.cameras.main.setZoom(3);
    this.cameras.main.setBounds(
      0,
      0,
      map.width * Graphics.default.environment.width,
      map.height * Graphics.default.environment.height
    );
    this.cameras.main.startFollow(this.player.sprite);
    // this.cameras.main.startFollow(this.player.spine);

    this.bCollisions = true;
    // this.physics.add.collider(this.player.sprite, map.wallLayer);
    this.physics.add.collider(this.player.sprite, map.wallLayer);
    this.physics.add.collider(this.monsterGroup, map.wallLayer);
    this.physics.add.collider(this.player.sprite, map.doorLayer);
    this.physics.add.collider(this.player.sprite, map.treasureLayer);
    this.physics.add.collider(this.player.sprite, map.blockLayer);
    // this.physics.add.collider(this.player.sprite, map.doorLayer);
    // this.physics.add.collider(this.player.sprite, map.blockLayer);
    this.physics.add.collider(this.monsterGroup, map.doorLayer);

    // // RMS is this needed for anything?
    this.physics.add.overlap(
      // this.player.sprite,
      this.player.sprite,
      this.monsterGroup,
      this.monsterPlayerCollide,
      undefined,
      this
    );

    // // Monster collider
    this.physics.add.collider(
      // this.player.sprite,
      this.player.sprite,
      this.monsterGroup,
      undefined,
      this.monsterPlayerCollide,
      this
    );

    // RMS Monster Beam collider
    // this.physics.add.collider(
    //   this.beam.triangle,
    //   this.monsterGroup,
    //   undefined,
    //   this.monsterPlayerCollide,
    //   this
    // );

    // for (let monster of this.monsters) {
    //   this.physics.add.collider(monster.sprite, map.wallLayer);
    // }
    this.input.keyboard.on('keydown_R', () => {
      this.scene.stop('InfoScene');
      this.scene.run('ReferenceScene');
      this.scene.sleep();
    });

    this.roomDebugGraphics = this.add.graphics({ x: 0, y: 0 });
    this.roomDebugGraphics.setVisible(false);
    this.roomDebugGraphics.lineStyle(2, 0xff5500, 0.5);

    for (let _i = 0, _a = map.rooms; _i < _a.length; _i++) {
      const room = _a[_i];
      this.roomDebugGraphics.strokeRect(
        this.tilemap.tileToWorldX(room.x),
        this.tilemap.tileToWorldY(room.y),
        this.tilemap.tileToWorldX(room.width),
        this.tilemap.tileToWorldY(room.height)
      );
    }

    this.scene.run('InfoScene');

    this.scene.run('Joystick');
    this.joystick = this.scene.get('Joystick');

    this.scene.run('GUI');
    this.gui = this.scene.get('GUI');

    this.gui.events.on(cmEvents.BUTTON_UP_PRESSED, () => {
      this.beam._incColor();
    });
    this.gui.events.on(cmEvents.BUTTON_DOWN_PRESSED, () => {
      this.beam._decColor();
    });
    // this.gui.events.on(cmEvents.BUTTON_ATTACK_PRESSED, () => {
    //   this.player.attack();
    // });

    // Keep this as the last line
    this.loading = false;
  };

  loadLevel(level) {
    console.log('Load Level:', this.loading, level);
    //if (!this.loading) {
    this.loading = true;
    const data = {
      dungeonUrl: this.dungeonUrl,
      dungeonLevel: this.dungeonLevel++
    };
    console.log('move to level', data);
    this.scene.start('DungeonScene', data);
    //}
  }

  loadNextLevel() {
    console.log('Next++');
    this.loadLevel(this.dungeonLevel++);
  }

  loadPrevLevel() {
    console.log('Prev++');
    //if (this.dungeonLevel > 1) {
    this.loadLevel(this.dungeonLevel--);
    //}
  }

  particleBurst(pointer) {
    this.emitter.x = pointer.x;
    this.emitter.y = pointer.y;
    this.emitter.start(true, 4000, null, 10);
    //  And 2 seconds later we'll destroy the emitter
    this.time.events.add(2000, destroyEmitter, this);
  }
  destroyEmitter() {
    this.emitter.destroy();
  }

  toggleColliders() {
    console.log(this);

    for (let i = 0; i < this.tilemap.layers.length; ++i) {
      if (this.bCollisions) {
        this.physics.destroy.collider(
          this.player.sprite,
          // this.player.spine,
          this.tilemap.layers[i]
        );
        // this.physics.destroy.collider(this.player.sprite, map.wallLayer);
        // this.physics.destroy.collider(this.monsterGroup, map.wallLayer);
        // this.physics.destroy.collider(this.player.sprite, map.doorLayer);
        // this.physics.destroy.collider(this.player.sprite, map.blockLayer);
        // this.physics.destroy.collider(this.monsterGroup, map.doorLayer);
      } else {
        this.physics.add.collider(this.player.sprite, this.tilemap.layers[i]);
        // this.physics.add.collider(this.player.spine, this.tilemap.layers[i]);
        // this.physics.add.collider(this.player.sprite, map.wallLayer);
        // this.physics.add.collider(this.monsterGroup, map.wallLayer);
        // this.physics.add.collider(this.player.sprite, map.doorLayer);
        // this.physics.add.collider(this.player.sprite, map.blockLayer);
        // this.physics.add.collider(this.monsterGroup, map.doorLayer);
      }
    }
    this.bCollisions = !this.bCollisions;
  }

  update(time, delta) {
    if (this.loading) return;
    this.player.update(time);
    for (var _i = 0, _a = this.monsters; _i < _a.length; _i++) {
      var monster = _a[_i];
      monster.update(time);
    }
    var player = new phaser.Math.Vector2({
      x: this.tilemap.worldToTileX(this.player.sprite.body.x),
      y: this.tilemap.worldToTileY(this.player.sprite.body.y)
      // x: this.tilemap.worldToTileX(this.player.spine.body.x),
      // y: this.tilemap.worldToTileY(this.player.spine.body.y)
    });
    const camera = this.cameras.main;
    var bounds = new phaser.Geom.Rectangle(
      this.tilemap.worldToTileX(camera.worldView.x) - 1,
      this.tilemap.worldToTileY(camera.worldView.y) - 1,
      this.tilemap.worldToTileX(camera.worldView.width) + 2,
      this.tilemap.worldToTileX(camera.worldView.height) + 2
    );
    this.fov.update(player, bounds, delta);
    this.beam.update(time, delta, this.player.sprite.x, this.player.sprite.y);
    // this.beam.update(time, delta, this.player.spine.x, this.player.spine.y);
  }
}
