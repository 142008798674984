import cmState from './cmState';
import cmStorage from './cmStorage';
import cmEvents from './cmEventController';

const cmAudioController = {
  currentTrack: null,
  context: null,
  init(context) {
    this.context = context;
    cmEvents.listen(cmEvents.STORAGE_KEY_CHANGE, ({ detail }) => {
      if (detail.hasOwnProperty('isMusicOn')) {
        this.context.sound.setMute(!detail['isMusicOn']);
      }
    });
  },
  playTrack(key) {
    if (!cmStorage.getValueForKey('isMusicOn')) {
      this.setMute(true);
    }
    if (key !== this.currentTrack) {
      this.stopByKey(key);
      this.context.sound.play(key);
      this.currentTrack = key;
    }
  },
  stopByKey(key) {
    this.context.sound.stopByKey(key);
  },
  playFX(key) {
    if (!cmState.settings.isSFXOn) {
      console.warn('sfx is currently toggle off');
      return;
    }
    this.context.sound.play(key);
  },
  stop() {
    this.context.sound.stopAll();
  },
  click() {
    cmState.settings.isSFXOn &&
      this.context &&
      this.context.sound.play('click');
  },
  setMute(value) {
    this.context.sound.setMute(value);
    cmState.settings.isMusicOn = !value;
  },
  setVolume(value) {
    this.context.sound.setVolume(value);
  }
};
export default cmAudioController;
window.cmAudioController = cmAudioController;
