import phaser from "phaser";
import Graphics from "../Graphics";

export default class Monster {
    constructor(x, y, scene) {
        console.log("Moster ", x, y);
        console.log(scene);

        // this.spine = scene.add.spine(x, y, 'monsters.alien').setScale(0.07);
        this.spine = scene.add.spine(x, y, 'monsters.dragon').setScale(0.05);
        // this.spine = scene.add.spine(x, y, 'monsters.spineboy', true).setScale(0.05);
        scene.physics.add.existing(this.spine);

        // this.sprite = scene.physics.add.sprite(x, y, Graphics.default.monster.name, 0);
        this.spine.name = "Monster";
        // this.sprite.setSize(12, 10);
        this.spine.setSize(12, 10);
        // this.sprite.setOffset(10, 14);
        // this.spine.setOffset(16, 0);
        this.spine.displayOriginY = 8;
        // this.sprite.anims.play(Graphics.default.monster.animations.idle.key);
        // this.sprite.setDepth(1);
        this.spine.setDepth(1);
        // this.body = this.sprite.body;
        this.body = this.spine.body;
        this.nextAction = 0;
        // this.body.bounce.set(0, 0);
        // this.body.setImmovable(true);
        this.body.setImmovable(true);

        this.color = 2;//Graphics.default.monster.color;
        this.speed = 20;//Graphics.default.monster.speed;

        this.spine.setInteractive();

        var anims = this.spine.getAnimationList();
        // console.log(this.spine)
        console.log(anims)

        this.spine.play(anims[0], true);

    }
    update(time) {
        if (time < this.nextAction) {
            return;
        }
        if (phaser.Math.Between(0, 1) === 0) {
            this.body.setVelocity(0);
            // this.sprite.anims.play(Graphics.default.monster.animations.idle.key, true);
        }
        else {
            // this.sprite.anims.play(Graphics.default.monster.animations.move.key, true);
            var direction = phaser.Math.Between(0, 3);
            this.body.setVelocity(0);
            if (!this.body.blocked.left && direction === 0) {
                this.body.setVelocityX(-this.speed);
            }
            else if (!this.body.blocked.right && direction <= 1) {
                this.body.setVelocityX(this.speed);
            }
            else if (!this.body.blocked.up && direction <= 2) {
                this.body.setVelocityY(-this.speed);
            }
            else if (!this.body.blocked.down && direction <= 3) {
                this.body.setVelocityY(this.speed);
            }
            else {
                console.log("Couldn't find direction for monster: " + direction);
            }
        }
        this.nextAction = time + phaser.Math.Between(1000, 3000);
    };
    kill() {
        // RMS this still causes some cleanup issues
        console.log("Kill Monster")
        this.spine.setActive(false);
        this.spine.setVisible(false);
        this.spine.destroy();
        // this.sprite.anims.play(Graphics.default.monster.animations.death.key, false);
        // this.sprite.disableBody();
    };
}