import phaser from "phaser";
import Graphics from "../Graphics";

export default class Block {
    constructor(type, worldx, worldy, scene) {
        this.worldx = worldx;
        this.worldy = worldy;
        this.type = type;
        this.scene = scene;
        this.emitter = null;
        this.timer = null;

    }

    destroyEmitter() {
        if (this.emitter) {
            this.emitter.stop();
        }
    }

    kill(colorID) {
        if (!this.emitter && this.scene) {
            var particles = this.scene.add.particles(Graphics.default.particles.name);
            particles.setDepth(6);

            this.emitter = particles.createEmitter({
                x: this.worldx,
                y: this.worldy,
                gravityY: 1000,
                gravityX: 0,
                scale: 0.05,
                speed: 100,
                lifespan: 500,
                blendMode: 'ADD',
                tint: Graphics.default.beam.colors[colorID]
            });

            this.emitter.start(true, 500, null, 10);
            //console.log(this.emitter)
            this.timer = this.scene.time.addEvent({
                delay: 300,
                callback: this.destroyEmitter,
                callbackScope: this,
                loop: false
            });

        }
    };
}