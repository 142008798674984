import axios from "axios";
const endpoint = "https://7m8yvv3gwa.execute-api.us-east-1.amazonaws.com/dev/mapdata";
import LZ from 'lz-string'

import localMap from '../BackendSim/createMap'





let USING_BACKEND = false;

export const getRoom = async (webaddress, version, level, width, height, compress) => {
  // try {
  const res = null;
  if (USING_BACKEND) {
    console.error("ERROR: Converted to front end for use with NFT/Crypto")
    const res = await axios.post(endpoint,
      { webaddress, version, level, width, height, compress },
      {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    );
    if (compress) {
      res.data = JSON.parse(LZ.decompressFromUTF16(res.data));
    }
    return res;
  } else {  // Using local
    let event = {
      body: JSON.stringify({
        webaddress: webaddress,
        version: version,
        level: level,
        width: width,
        height: height,
        compress: compress,
        debugmap: 0
      })
    }
    let context = {};
    let out = {}
    localMap.createmap(event, context, function (err, res) {
      if (compress) {
        out.data = JSON.parse(LZ.decompressFromUTF16(res.body));
      } else {
        out.data = res.body
      }
      return (out)
    })
    return (out)
  }
  // } catch (e) {
  //   console.error(e);
  // }
}