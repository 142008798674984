// "use strict";
// Object.defineProperty(exports, "__esModule", { value: true });
exports.TileType = void 0;
var Graphics = require("../Graphics");
var TileType;
(function (TileType) {
    TileType[TileType["None"] = 0] = "None";
    TileType[TileType["Wall"] = 1] = "Wall";
    TileType[TileType["Door"] = 2] = "Door";
})(TileType = exports.TileType || (exports.TileType = {}));
var Tile = /** @class */ (function () {
    function Tile(type, x, y, map) {
        this.type = type;
        this.collides = type !== TileType.None;
        this.map = map;
        this.x = x;
        this.y = y;
        this.seen = false;
        this.desiredAlpha = 1;
        this.corridor = !map.withinRoom(x, y);
    }

    Tile.tileTypeFor = function (type) {
        if (type === "wall") {
            return TileType.Wall;
        }
        else if (type === "door") {
            return TileType.Door;
        }
        else {
            return TileType.None;
        }
    };
    Tile.prototype.open = function () {
        this.collides = false;
    };
    Tile.prototype.neighbours = function () {
        return {
            n: this.map.tileAt(this.x, this.y - 1),
            s: this.map.tileAt(this.x, this.y + 1),
            w: this.map.tileAt(this.x - 1, this.y),
            e: this.map.tileAt(this.x + 1, this.y),
            nw: this.map.tileAt(this.x - 1, this.y - 1),
            ne: this.map.tileAt(this.x + 1, this.y - 1),
            sw: this.map.tileAt(this.x - 1, this.y + 1),
            se: this.map.tileAt(this.x + 1, this.y + 1)
        };
    };
    Tile.prototype.isEnclosed = function () {
        var _this = this;
        return (Object.values(this.neighbours()).filter(function (t) { return !t || (t.type === TileType.Wall && t.corridor === _this.corridor); }).length === 8);
    };
    // Tile.prototype.spriteIndex = function () {
    //     var modifier = this.type === TileType.Wall && this.corridor ? 8 : 0;
    //     return this.rawIndex() + modifier;
    // };
    // // prettier-ignore
    // Tile.prototype.rawIndex = function () {
    //     var neighbours = this.neighbours();
    //     var n = neighbours.n && neighbours.n.type === TileType.Wall && neighbours.n.corridor === this.corridor;
    //     var s = neighbours.s && neighbours.s.type === TileType.Wall && neighbours.s.corridor === this.corridor;
    //     var w = neighbours.w && neighbours.w.type === TileType.Wall && neighbours.w.corridor === this.corridor;
    //     var e = neighbours.e && neighbours.e.type === TileType.Wall && neighbours.e.corridor === this.corridor;
    //     var wDoor = neighbours.w && neighbours.w.type === TileType.Door;
    //     var eDoor = neighbours.e && neighbours.e.type === TileType.Door;
    //     var i = Graphics.default.environment.indices.walls;
    //     if (this.type === TileType.Wall) {
    //         if (n && e && s && w) {
    //             return i.intersections.n_e_s_w;
    //         }
    //         if (n && e && s) {
    //             return i.intersections.n_e_s;
    //         }
    //         if (n && s && w) {
    //             return i.intersections.n_s_w;
    //         }
    //         if (e && s && w) {
    //             return i.intersections.e_s_w;
    //         }
    //         if (n && e && w) {
    //             return i.intersections.n_e_w;
    //         }
    //         if (e && s) {
    //             return i.intersections.e_s;
    //         }
    //         if (e && w) {
    //             return i.intersections.e_w;
    //         }
    //         if (s && w) {
    //             return i.intersections.s_w;
    //         }
    //         if (n && s) {
    //             return i.intersections.n_s;
    //         }
    //         if (n && e) {
    //             return i.intersections.n_e;
    //         }
    //         if (n && w) {
    //             return i.intersections.n_w;
    //         }
    //         if (w && eDoor) {
    //             return i.intersections.e_door;
    //         }
    //         if (e && wDoor) {
    //             return i.intersections.w_door;
    //         }
    //         if (n) {
    //             return i.intersections.n;
    //         }
    //         if (s) {
    //             return i.intersections.s;
    //         }
    //         if (e) {
    //             return i.intersections.e;
    //         }
    //         if (w) {
    //             return i.intersections.w;
    //         }
    //         return i.alone;
    //     }
    //     if (this.type === TileType.Door) {
    //         if (n || s) {
    //             return Graphics.default.environment.indices.doors.vertical;
    //         }
    //         else {
    //             return Graphics.default.environment.indices.doors.horizontal;
    //         }
    //     }
    //     return 0;
    // };
    return Tile;
}());
exports.default = Tile;
