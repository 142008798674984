import { uniq } from 'lodash';
import Graphics from '../Graphics';

export default class Beam {
  constructor(map, x, y, scene) {
    this.scene = scene;

    this.map = map;

    this.updateRate = Graphics.default.beam.updateRate; //50; //ms
    this.nextAction = 0;
    this.range = Graphics.default.beam.initial.range; // 4; // distance (in map tiles) the light will shine
    this.spread = Graphics.default.beam.initial.spread; //40; // angle of separation from leading edge to back of beam
    this.speed = Graphics.default.beam.initial.speed; //0.5; // degrees per update

    this.alpha = Graphics.default.beam.alpha; //0.75;
    this.alphaflutter = Graphics.default.beam.alphaflutter; //0.25;
    this.seen = false;

    this.colorId = 1; //0x6666ff; // color
    this.color = this.getBeamColorById(1);

    this.triangle = scene.add.triangle(
      x, //this.beamx,
      y, //this.beamy,
      0,
      this.range * Graphics.default.beam.sizeAdjust, //this.width,
      this.spread,
      this.range * Graphics.default.beam.sizeAdjust, //this.width,
      this.spread / 2,
      0,
      this.color,
      this.alpha
    );
    this.triangle.setOrigin(
      Graphics.default.beam.originXoffset,
      Graphics.default.beam.originYoffset
    );

    // this.triangle.fillGradientStyle(0xff0000, 0xff0000, 0xffff00, 0xffff00, 1);

    this.triangle.angle = Graphics.default.beam.initial.angle; //180; // degrees

    // this.triangle.setAlpha(this.alpha);
    scene.tweens.add({
      targets: this.triangle,
      alpha: this.alphaflutter,
      //angle: 360,
      yoyo: true,
      repeat: -1,
      ease: 'Sine.easeInOut'
    });

    let at = this.calcAffectedTiles();

    this.scene.event;
  }

  // setBeamPos(x, y) {
  //   this.beamx = x;
  //   this.beamy = y;
  // }

  // return hex value of beam
  getColor() {
    return Graphics.default.beam.colors[this.colorId];
  }
  getNextColor() {
    return this.getBeamColorByIdWithoutSettingColorId(this.colorId + 1);
  }
  getPreviousColor() {
    return this.getBeamColorByIdWithoutSettingColorId(this.colorId - 1);
  }

  getBeamColorByIdWithoutSettingColorId(colorId) {
    let l = Graphics.default.beam.colors.length;
    if (colorId < 0) {
      return Graphics.default.beam.colors[l - 1];
    }
    return Graphics.default.beam.colors[colorId % l];
  }

  // this func doesn't just get the value, it sets this.colorId
  getBeamColorById(colorId) {
    let l = Graphics.default.beam.colors.length;
    if (colorId < 0) {
      this.colorId = l - 1;
    } else {
      this.colorId = colorId % l;
    }
    return Graphics.default.beam.colors[this.colorId];
  }

  _incColor() {
    this.triangle.fillColor = this.incrColor();
    return this.getColor();
  }

  _decColor() {
    this.triangle.fillColor = this.decrColor();
    return this.getColor();
  }

  incrColor() {
    return (this.color = this.getBeamColorById(this.colorId + 1));
  }

  decrColor() {
    return (this.color = this.getBeamColorById(this.colorId - 1));
  }

  incrRange() {
    if (this.range < Graphics.default.beam.maxrange) this.range++;
    this.triangle.displayHeight = this.range * Graphics.default.beam.sizeAdjust;
    return this.range;
  }

  decrRange() {
    if (this.range > 2) this.range--;
    this.triangle.displayHeight = this.range * Graphics.default.beam.sizeAdjust;
    return this.range;
  }

  incrSpread() {
    if (this.spread < 60) this.spread++;
    this.triangle.displayWidth = this.spread;
    return this.spread;
  }

  decrSpread() {
    if (this.spread > 2) this.spread--;
    this.triangle.displayWidth = this.spread;
    return this.spread;
  }

  incrSpeed() {
    if (this.speed < 1) this.speed += Graphics.default.beam.speedIncrement;
    return this.speed;
  }

  decrSpeed() {
    if (this.speed > 0) this.speed -= Graphics.default.beam.speedIncrement;
    return this.speed;
  }

  setBeamAngle(degrees) {
    this.triangle.angle = degrees;
  }

  // fix this to take into account width - and not have to pass in this
  calcAffectedTiles(tilex, tiley) {
    let at = [];

    let start = {
      x: tilex,
      y: tiley
    };
    let end = {
      x:
        start.x + this.range * -Math.sin(this.triangle.angle * (Math.PI / 180)),
      y: start.y + this.range * Math.cos(this.triangle.angle * (Math.PI / 180))
    };
    let incr = {
      x: (end.x - start.x) / this.range,
      y: (end.y - start.y) / this.range
    };
    let x = start.x;
    let y = start.y;
    for (let i = 0; i < this.range; ++i) {
      at.push([Math.floor(x + 0.5), Math.floor(y + 0.5)]);
      x += incr.x;
      y += incr.y;
    }
    at = uniq(at);

    return at;
  }

  update(time, dt, x, y) {
    if (time < this.nextAction) {
      return;
    }
    this.nextAction = time + this.updateRate;

    this.triangle.setPosition(x, y - Graphics.default.beam.headPlacementYoffset);
    this.triangle.angle = (this.triangle.angle + this.speed * dt) % 360;

    let tilex = this.map.tilemap.worldToTileX(x);
    let tiley = this.map.tilemap.worldToTileY(y);

    // Tile Y is offset by 1 since the characters head is 1 tile up
    const at = this.calcAffectedTiles(tilex, tiley - 1);
    this.map.destroyBlockList(at, this.colorId);

    // let tile = this.map.tilemap.getTileAt(tilex, tiley);//[tiley][tilex];
    // console.log(tile)
  }
  kill() { }
}
