import cmEventController from './cmEventController';
const keyPrefix = 'crypto-miner-storage-';

const cmStorage = {
  makeKey(key) {
    return keyPrefix + key;
  },
  getValueForKey(key, defaultValue) {
    const value = localStorage.getItem(keyPrefix + key);


    // set default value
    if (value === null && defaultValue !== undefined) {
      localStorage.setItem(keyPrefix + key, defaultValue);
      if (defaultValue === 'true') return true;
      if (defaultValue === 'false') return false;
      return defaultValue;
    }

    if (value === 'true') return true;
    if (value === 'false') return false;

    // console.log("GET Value for ", key, value)

    return value;
  },
  setValueForKey(key, value) {
    localStorage.setItem(keyPrefix + key, value);
    cmEventController.fire(cmEventController.STORAGE_KEY_CHANGE, {
      [key]: value
    });
    return value;
  },
  removeKey(key) {
    localStorage.removeItem(keyPrefix + key);
  },
  __resetAll() {
    localStorage.clear();
  }
};
export default cmStorage;
window.cmStorage = cmStorage;
