import { Scene } from 'phaser';
import { getScaleFactor } from '../utils/utils';

export default class Joystick extends Scene {
  up = false;
  down = false;
  left = false;
  right = false;

  pointerId = -1;

  controller = null;
  controllerFrame = null;
  glow = null;

  constructor() {
    super({ key: 'Joystick' });
    this.gui = game.scene.getScene('GUI');
  }

  preload() { }
  create() {
    if (!window.isMobile) {
      return;
    }
    const tollerance = 40;
    const play = tollerance * 0.3;
    const x = window.innerWidth * 0.84;
    const y = window.innerHeight * 0.8;
    this.controllerFrame = this.add
      .image(x, y, 'gui', 'controller/m_controller_frame')
      .setScale(getScaleFactor())
      .setInteractive()
      .on('pointerdown', ({ id }) => {
        this.pointerId = id;
        this.controller.isDown = true;
        this.glow.setVisible(true);
      });
    this.glow = this.add
      .image(x, y, 'gui', 'controller/m_controller_active_glow')
      .setScale(getScaleFactor())
      .setVisible(false);
    this.controller = this.add
      .image(x, y, 'gui', 'controller/m_controller_inner')
      .setScale(getScaleFactor());
    this.controller.startX = x;
    this.controller.startY = y;
    this.controller.isDown = false;

    this.input.on('pointermove', ({ position, id }) => {
      if (!this.controller.isDown || this.pointerId !== id) return;
      // get amount moved
      const difference = new Phaser.Math.Vector2(
        position.x,
        position.y
      ).subtract(
        new Phaser.Math.Vector2(this.controllerFrame.x, this.controllerFrame.y)
      );
      // clamp the difference
      const pos = new Phaser.Math.Vector2(
        Phaser.Math.Clamp(difference.x, -tollerance, tollerance),
        Phaser.Math.Clamp(difference.y, -tollerance, tollerance)
      );
      const newPos = new Phaser.Math.Vector2(
        this.controllerFrame.x,
        this.controllerFrame.y
      ).add(pos);
      this.controller.setPosition(newPos.x, newPos.y);

      if (pos.x > play) {
        this.right = true;
      } else if (pos.x < -play) {
        this.left = true;
      } else {
        this.right = this.left = false;
      }
      if (pos.y > play) {
        this.down = true;
      } else if (pos.y < -play) {
        this.up = true;
      } else {
        this.up = this.down = false;
      }
    });

    this.input.on('pointerup', ({ id }) => {
      if (!this.controller.isDown || this.pointerId != id) return;
      this.controller.isDown = false;
      this.up = this.down = this.left = this.right = false;
      this.controller.setPosition(
        this.controller.startX,
        this.controller.startY
      );
      this.controller.isDown = false;
      this.glow.setVisible(false);
      this.up = this.down = this.left = this.right = false;

      this.tweens.add({
        targets: this.controller,
        x: this.controller.startX,
        y: this.controller.startY,
        ease: 'Back.easeOut',
        duration: 250
        // repeat: -1
      });
    });
    this.scale.on('resize', this.onResize, this);
    this.onResize({ width: window.innerWidth, height: window.innerHeight });
  }

  onResize(size) {
    const { width, height } = size;
    const scale = getScaleFactor() + 0.2;

    this.controllerFrame.setScale(scale);
    this.glow.setScale(scale);
    this.controller.setScale(scale);

    const x = width * 0.8;
    const y = height * 0.8;
    this.controllerFrame.setPosition(x, y);
    this.glow.setPosition(x, y);
    this.controller.setPosition(x, y);
    this.controller.startX = x;
    this.controller.startY = y;
  }
}
