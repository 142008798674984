import cmAudioController from './cmAudioController';
import cmState from './cmState';
import cmUIController from './cmUIController';

const cmEventController = {
  fire(eventName, detail = {}) {
    document.dispatchEvent(new CustomEvent(eventName, { detail }));
  },
  listen(eventName, callback) {
    document.addEventListener(eventName, callback);
  },
  stopListening(eventName, callback) {
    document.removeEventListener(eventName, callback);
  },
  // arrow button events for color change
  BUTTON_UP_PRESSED: 'BUTTON_UP_PRESSED',
  BUTTON_UP_RELEASED: 'BUTTON_UP_RELEASED',
  BUTTON_DOWN_PRESSED: 'BUTTON_DOWN_PRESSED',
  BUTTON_DOWN_RELEASED: 'BUTTON_DOWN_RELEASED',

  // attack button events
  BUTTON_ATTACK_PRESSED: 'BUTTON_ATTACK_PRESSED',

  // Dom events
  MENU_BUTTON_CLICKED: 'MENU_BUTTON_CLICKED',

  // character view
  TOGGLE_CHARACTER_VIEW: 'TOGGLE_CHARACTER_VIEW',
  OPEN_CHARACTER_VIEW: 'OPEN_CHARACTER_VIEW',
  CLOSE_CHARACTER_VIEW: 'CLOSE_CHARACTER_VIEW',

  // anytime the setKeyForValue is called in utils, this fires
  STORAGE_KEY_CHANGE: 'STORAGE_KEY_CHANGE',

  // touch events
  SWIPE_UP: 'SWIPE_UP',
  SWIPE_DOWN: 'SWIPE_DOWN',
  SWIPE_LEFT: 'SWIPE_LEFT',
  SWIPE_RIGHT: 'SWIPE_RIGHT',
  TOUCH_MOVE: 'TOUCH_MOVE',
  TOUCH_START: 'TOUCH_START',
  TOUCH_END: 'TOUCH_END',
  TOUCH: 'TOUCH',
  TOUCH_DOUBLE: 'TOUCH_DOUBLE',

  // color orb events
  COLOR_ORB_MAX_REACHED: 'COLOR_ORB_MAX_REACHED',
  COLOR_ORB_INCREASE: 'COLOR_ORB_INCREASE',

  COINS_CHANGED: 'COINS_CHANGED'
};
export default cmEventController;

document.addEventListener(cmEventController.COINS_CHANGED, (e) => {
  cmUIController.updateCoinValue(e);
});
document.addEventListener(cmEventController.COLOR_ORB_INCREASE, () => {
  cmState.addCoin();
  cmAudioController.playFX('coin');
});
document.addEventListener(cmEventController.COLOR_ORB_MAX_REACHED, () => {
  cmState.addCoin();
  cmState.addCoin();
  cmState.addCoin();
  cmState.addCoin();
  cmState.addCoin();
  cmAudioController.playFX('correct');
});
