'use strict';

// const uuid = require('uuid');
// const AWS = require('aws-sdk'); // eslint-disable-line import/no-extraneous-dependencies
const dungeoneer = require('dungeoneer')
const seedrandom = require('seedrandom');
const lz = require('lz-string');
const Graphics = require('../Graphics')

const logging = Graphics.default.logging;
// [
//   /* 000 */ '\u250F', '\u254B', '\u2501', '\u2501', '\u2503', '\u2024', '\u2024', '\u2024', '\u250F', '\u254B', '\u2501', '\u2501', '\u2503', '\u2024', '\u2024', '\u2024',
//   /* 016 */ '\u2523', '\u251B', '\u257C', '\u252B', '\u2578', '\u2024', '\u2024', '\u2024', '\u2523', '\u251B', '\u257C', '\u252B', '\u2578', '\u2024', '\u2024', '\u2024',
//   /* 032 */ '\u2503', '\u257D', '\u2533', '\u254F', '\u2573', '\u2573', '\u2573', '\u2573', '\u2503', '\u257D', '\u2533', '\u254F', '\u2573', '\u2573', '\u2573', '\u2573',
//   /* 048 */ '\u2517', '\u253B', '\u257F', '\u251B', '\u2573', '\u2573', '\u2573', '\u2573', '\u2517', '\u253B', '\u257F', '\u251B', '\u2573', '\u2573', '\u2573', '\u2573',
//   /* 064 */ '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573',
//   /* 080 */ '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573',
//   /* 096 */ '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2510', '\u25A0', '\u250C', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573',
//   /* 112 */ '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2198', '\u2197', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573',
//   /* 128 */ '\u258C', '\u25AF', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573',
//   /* 144 */ '\u258C', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573',
//   /* 160 */ '\u258C', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573',
//   /* 176 */ '\u258C', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573',
//   /* 192 */ '$', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573', '\u2573',
// ]

const reference = Graphics.default.environment;
// {
//   filename: "Env6x16-extruded.png",
//   indices: {
//     unknown: 0x66,
//     floor: {
//       outer: [0x05, 0x05, 0x05, 0x15, 0x07, 0x17],
//       outerCorridor: [0x0d, 0x0d, 0x0d, 0x1d, 0x0f, 0x1f],
//     },
//     block: 0x17,
//     treasure: {
//       closed: 0xC0,
//       destroyed: 0x26,
//       open: [0xC1, 0xC2, 0xC3, 0xC4, 0xC5, 0xC6],
//     },
//     doors: {
//       horizontal: 0x81,
//       vertical: 0x90,
//       destroyed: 0x35,
//     },
//     stairs: {
//       up: 0x76,
//       down: 0x75,
//     },
//     walls: {
//       intersections: {
//         alone: 0x14,
//         allwall: 0xfe,
//         es: 0x00,
//         nesw: 0x01,
//         ew: 0x02,
//         sw: 0x03,
//         nes: 0x10,
//         w: 0x11,
//         e: 0x12,
//         nsw: 0x13,
//         ns: 0x20,
//         s: 0x21,
//         esw: 0x22,
//         ne: 0x30,
//         new: 0x31,
//         n: 0x32,
//         nw: 0x33,
//         e_door: 0x65,
//         w_door: 0x67,
//       }
//     }
//   }
// }


module.exports.createmap = (event, context, callback) => {
  const timestamp = new Date().getTime();
  console.log("createmap: ", timestamp);

  const data = JSON.parse(event.body);
  console.log("REQUEST Params:");
  console.log(data);

  if (typeof data.webaddress !== 'string') {
    console.error('Validation Failed');
    callback(null, {
      statusCode: 400,
      headers: {
        'Content-Type': 'text/plain',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
      },
      body: 'Couldn\'t create the comment item, no user.',
    });
    return;
  }



  const w = data.width ?? 101;
  const h = data.height ?? 101;
  const compress = data.compress ?? 1;
  const coverwall = data.coverwall ?? 0;

  const level = data.level ?? 0;
  const seed = '[' + level + ']' + data.webaddress;

  const debugmap = data.debugmap ?? 0;

  const dungeon = dungeoneer.build({
    width: w,
    height: h,
    seed: seed
  });

  let responseData = {};


  //------------------------------------------------------------------------
  //
  //  Version 0.1.1
  //
  //------------------------------------------------------------------------
  if (data.version === "0.1.1") {
    responseData.version = "0.1.1"
    responseData.level = level;
    // responseData.version = data.version;
    responseData.reference = reference;

    responseData.rooms = [];
    // console.log( "rooms:", dungeon.rooms.length);

    let startRoom = Math.floor(dungeon.rooms.length / 2);
    let startPosition = { x: 50, y: 50 };


    for (let i = 0; i < dungeon.rooms.length; ++i) {
      responseData.rooms.push(dungeon.rooms[i])
      if (startRoom === i) {
        startPosition.x = dungeon.rooms[i].x + Math.floor(dungeon.rooms[i].width / 2);
        startPosition.y = dungeon.rooms[i].y + Math.floor(dungeon.rooms[i].height / 2);
      }
    }
    responseData.startRoom = startRoom;
    responseData.startPosition = startPosition;
    responseData.numLevels = 1;
    responseData.blockVariance = Math.floor(level / 5) + 3;

    responseData.tiles = [];
    responseData.blocks = [];

    var rnd = seedrandom(seed);

    // console.log( "tiles:",dungeon.tiles.length);
    for (let i = 0; i < dungeon.tiles.length; ++i) {
      responseData.tiles[i] = [];
      responseData.blocks[i] = [];
      for (let j = 0; j < dungeon.tiles[i].length; ++j) {
        responseData.tiles[i][j] = 55;
        responseData.blocks[i][j] = Math.floor(rnd() * responseData.blockVariance);;

        let type = dungeon.tiles[i][j].type;

        let t = 55; // 
        if (type === 'wall') {
          let neighbours = '';
          neighbours += (dungeon.tiles[i][j].neighbours.n && dungeon.tiles[i][j].neighbours.n.type === 'wall') ? 'n' : '';
          neighbours += (dungeon.tiles[i][j].neighbours.e && dungeon.tiles[i][j].neighbours.e.type === 'wall') ? 'e' : '';
          neighbours += (dungeon.tiles[i][j].neighbours.s && dungeon.tiles[i][j].neighbours.s.type === 'wall') ? 's' : '';
          neighbours += (dungeon.tiles[i][j].neighbours.w && dungeon.tiles[i][j].neighbours.w.type === 'wall') ? 'w' : '';

          t = reference.indices.walls.intersections[neighbours] ?? 55;
        }
        else if (type === 'door') {
          t = reference.indices.doors.horizontal; // 129;
        }
        else //if (type==='floor')
        {
          t = reference.indices.floor.outer[4]; // 7;
        }
        // (dungeon.tiles[i][j].region) ?
        //   responseData.tiles[i][j].region = dungeon.tiles[i][j].region : {};
        responseData.tiles[i][j] = t;
      }
    }
    responseData.seed = dungeon.seed;
  }
  //------------------------------------------------------------------------
  //
  //  Version 0.1.2
  //
  //------------------------------------------------------------------------
  else if (data.version === "0.1.2") {
    responseData.version = "0.1.2"
    responseData.level = level;
    // responseData.version = data.version;
    responseData.reference = reference;

    responseData.rooms = [];
    // console.log( "rooms:", dungeon.rooms.length);

    let startRoom = Math.floor(dungeon.rooms.length / 2);
    let startPosition = { x: 50, y: 50 };

    for (let i = 0; i < dungeon.rooms.length; ++i) {
      responseData.rooms.push(dungeon.rooms[i])
      if (startRoom === i) {
        startPosition.x = dungeon.rooms[i].x + Math.floor(dungeon.rooms[i].width / 2);
        startPosition.y = dungeon.rooms[i].y + Math.floor(dungeon.rooms[i].height / 2);
      }
    }
    responseData.size = { w, h };
    responseData.startRoom = startRoom;
    responseData.startPosition = startPosition;
    responseData.numLevels = 1;
    responseData.blockVariance = Math.floor(level / 5) + 3;

    responseData.tiles = [];
    responseData.blocks = [];

    var rnd = seedrandom(seed);

    // console.log( "tiles:",dungeon.tiles.length);
    for (let i = 0; i < dungeon.tiles.length; ++i) {
      responseData.tiles[i] = [];
      responseData.blocks[i] = [];
      for (let j = 0; j < dungeon.tiles[i].length; ++j) {
        responseData.tiles[i][j] = 55;
        responseData.blocks[i][j] = Math.floor(rnd() * responseData.blockVariance);;

        let type = dungeon.tiles[i][j].type;

        let t = 55; // 
        if (type === 'wall') {
          let neighbours = '';
          neighbours += (dungeon.tiles[i][j].neighbours.n && dungeon.tiles[i][j].neighbours.n.type === 'wall') ? 'n' : '';
          neighbours += (dungeon.tiles[i][j].neighbours.e && dungeon.tiles[i][j].neighbours.e.type === 'wall') ? 'e' : '';
          neighbours += (dungeon.tiles[i][j].neighbours.s && dungeon.tiles[i][j].neighbours.s.type === 'wall') ? 's' : '';
          neighbours += (dungeon.tiles[i][j].neighbours.w && dungeon.tiles[i][j].neighbours.w.type === 'wall') ? 'w' : '';

          const wDoor = (dungeon.tiles[i][j].neighbours.w) ? (dungeon.tiles[i][j].neighbours.w && dungeon.tiles[i][j].neighbours.w.type === 'door') : '';
          const eDoor = (dungeon.tiles[i][j].neighbours.e) ? (dungeon.tiles[i][j].neighbours.e && dungeon.tiles[i][j].neighbours.e.type === 'door') : '';

          //const allwalls = 
          if (neighbours === 'nesw' &&
            (dungeon.tiles[i][j].neighbours.ne && dungeon.tiles[i][j].neighbours.ne.type === 'wall') &&
            (dungeon.tiles[i][j].neighbours.nw && dungeon.tiles[i][j].neighbours.nw.type === 'wall') &&
            (dungeon.tiles[i][j].neighbours.se && dungeon.tiles[i][j].neighbours.se.type === 'wall') &&
            (dungeon.tiles[i][j].neighbours.sw && dungeon.tiles[i][j].neighbours.sw.type === 'wall')
          ) { neighbours = 'allwalls' };

          //const alone = 
          if (neighbours === '') { neighbours = 'alone' };

          // Set to 55 if blank?
          t = reference.indices.walls.intersections[neighbours] ?? 55;

        }
        else if (type === 'door') {
          const nWall = (dungeon.tiles[i][j].neighbours.n) ? (dungeon.tiles[i][j].neighbours.n && dungeon.tiles[i][j].neighbours.n.type === 'wall') : '';
          const sWall = (dungeon.tiles[i][j].neighbours.s) ? (dungeon.tiles[i][j].neighbours.s && dungeon.tiles[i][j].neighbours.s.type === 'wall') : '';
          //t = reference.indices.doors.horizontal; 
          if (nWall || sWall) {
            t = reference.indices.doors.vertical;
          } else {
            t = reference.indices.doors.horizontal;
          }
        }
        else //if (type==='floor')
        {
          t = reference.indices.floor.outer[4]; // 7;
        }
        // (dungeon.tiles[i][j].region) ?
        //   responseData.tiles[i][j].region = dungeon.tiles[i][j].region : {};
        responseData.tiles[i][j] = t;
      }
    }
    responseData.seed = dungeon.seed;
  }
  //------------------------------------------------------------------------
  //
  //  Version 0.1.3
  //
  //------------------------------------------------------------------------
  else {// LATEST VERSION
    responseData.version = "0.1.3"
    responseData.level = level;
    // responseData.version = data.version;
    responseData.reference = reference;

    responseData.rooms = [];
    responseData.monsters = [];
    responseData.treasure = [];
    // console.log( "rooms:", dungeon.rooms.length);
    var rnd = seedrandom(seed);

    let startRoom = Math.floor(dungeon.rooms.length / 2);
    let startPosition = { x: 50, y: 50 };

    responseData.size = { w, h };
    responseData.startRoom = startRoom;
    responseData.startPosition = startPosition;
    responseData.numLevels = 1;
    responseData.blockVariance = 3; //Math.floor(level / 5) + 3;

    responseData.tiles = [];
    responseData.blocks = [];


    // Layout tiles
    for (let i = 0; i < dungeon.tiles.length; ++i) {
      responseData.tiles[i] = [];
      responseData.blocks[i] = [];
      for (let j = 0; j < dungeon.tiles[i].length; ++j) {
        responseData.tiles[i][j] = 55;

        if (!coverwall && dungeon.tiles[i][j].type === 'wall') {
          responseData.blocks[i][j] = 0;
        } else {
          responseData.blocks[i][j] = Math.floor(rnd() * responseData.blockVariance) + 1;
        }

        responseData.tiles[i][j] = generateTile(i, j, dungeon);
      }
    }

    // Layout stairs

    let rup = Math.floor(rnd() * dungeon.rooms.length);
    let rdn = Math.floor(rnd() * dungeon.rooms.length);

    while (startRoom === rup) {
      rup = Math.floor(rnd() * dungeon.rooms.length);
    }

    while (startRoom === rdn) {
      rdn = Math.floor(rnd() * dungeon.rooms.length);
    }

    responseData.stairs = {
      down: {
        position: {
          x: dungeon.rooms[rdn].x + Math.floor(dungeon.rooms[rdn].width * rnd()),
          y: dungeon.rooms[rdn].y + Math.floor(dungeon.rooms[rdn].height * rnd())
        }
      }
    }

    if (level > 1) {
      responseData.stairs.up = {
        position: {
          x: dungeon.rooms[rup].x + Math.floor(dungeon.rooms[rup].width * rnd()),
          y: dungeon.rooms[rup].y + Math.floor(dungeon.rooms[rup].height * rnd())
        }
      }
      // Make sure they are not on top of each other
      while ((responseData.stairs.up.position.x === responseData.stairs.down.position.x) &&
        (responseData.stairs.up.position.y === responseData.stairs.down.position.y)) {
        rdn = Math.floor(rnd() * dungeon.rooms.length);
        responseData.stairs.up.position.y = dungeon.rooms[rdn].y + Math.floor(dungeon.rooms[rdn].height * rnd())
        responseData.stairs.up.position.x = dungeon.rooms[rdn].x + Math.floor(dungeon.rooms[rdn].width * rnd())
      }
      responseData.tiles[responseData.stairs.up.position.x][responseData.stairs.up.position.y] = reference.indices.stairs.up;
    }
    responseData.tiles[responseData.stairs.down.position.x][responseData.stairs.down.position.y] = reference.indices.stairs.down;

    // Layout monsters
    // Layout treasure
    for (let i = 0; i < dungeon.rooms.length; ++i) {
      responseData.rooms.push(dungeon.rooms[i])
      if (startRoom === i) {
        startPosition.x = dungeon.rooms[i].x + Math.floor(dungeon.rooms[i].width / 2);
        startPosition.y = dungeon.rooms[i].y + Math.floor(dungeon.rooms[i].height / 2);
      } else {
        // Are there monsters?
        let numMonsters = Math.floor((responseData.level * rnd()))
        for (let n = 0; n < numMonsters; ++n) {
          let m = {
            position: {
              x: dungeon.rooms[i].x + Math.floor(dungeon.rooms[i].width / 2),
              y: dungeon.rooms[i].y + Math.floor(dungeon.rooms[i].height / 2)
            },
            type: 1,
            color: 1
          }
          responseData.monsters.push(m);
        }

        // Is there treasure
        if (rnd() * 3 > 2) {
          let treasure = {
            position: {
              x: dungeon.rooms[i].x + Math.floor(dungeon.rooms[i].width * rnd()),
              y: dungeon.rooms[i].y + Math.floor(dungeon.rooms[i].height * rnd())
            },
            type: 1,
            amount: [Math.floor(rnd() * responseData.level * 10) + 1],
            color: 0
          }
          responseData.treasure.push(treasure);
          responseData.tiles[treasure.position.x][treasure.position.y] = reference.indices.treasure.closed;
        }
      }
    }
    responseData.seed = dungeon.seed;
  }

  // Export information if debugmap is trye
  if (debugmap) {
    console.log("RESPONSE Version: ", responseData.version);
    console.log('\u263A - Player Start');
    console.log(responseData.rooms);
    console.log('\u24C2 - Monster');
    console.log(responseData.monsters);
    console.log('$ - Treasure');
    console.log(responseData.treasure);

    let i = 0;

    for (i = 0; i < responseData.tiles.length; ++i) {
      let str = '';
      for (let j = 0; j < responseData.tiles[i].length; ++j) {
        if (responseData.startPosition.x === j && responseData.startPosition.y === i) {
          str += '\u263A';
        } else {
          str += logging[responseData.tiles[j][i]];
        }
      }
      console.log(str);
    }
  }

  let body = (compress) ?
    lz.compressToUTF16(JSON.stringify(responseData)) :
    JSON.stringify(responseData);

  // create a response
  const response = {
    statusCode: 200,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    },
    body: body,
  };
  callback(null, response);
  return;
};



//
// Create the layout based on the dungeon
//
function generateTile(i, j, dungeon) {
  let type = dungeon.tiles[i][j].type;
  let t = reference.indices.unknown; // 55; // 
  if (type === 'wall') {
    let neighbours = '';
    neighbours += (dungeon.tiles[i][j].neighbours.n && dungeon.tiles[i][j].neighbours.n.type === 'wall') ? 'n' : '';
    neighbours += (dungeon.tiles[i][j].neighbours.e && dungeon.tiles[i][j].neighbours.e.type === 'wall') ? 'e' : '';
    neighbours += (dungeon.tiles[i][j].neighbours.s && dungeon.tiles[i][j].neighbours.s.type === 'wall') ? 's' : '';
    neighbours += (dungeon.tiles[i][j].neighbours.w && dungeon.tiles[i][j].neighbours.w.type === 'wall') ? 'w' : '';

    const wDoor = (dungeon.tiles[i][j].neighbours.w) ? (dungeon.tiles[i][j].neighbours.w && dungeon.tiles[i][j].neighbours.w.type === 'door') : '';
    const eDoor = (dungeon.tiles[i][j].neighbours.e) ? (dungeon.tiles[i][j].neighbours.e && dungeon.tiles[i][j].neighbours.e.type === 'door') : '';

    //const allwalls = 
    // if (neighbours === 'nesw' &&
    //   (dungeon.tiles[i][j].neighbours.ne && dungeon.tiles[i][j].neighbours.ne.type === 'wall') &&
    //   (dungeon.tiles[i][j].neighbours.nw && dungeon.tiles[i][j].neighbours.nw.type === 'wall') &&
    //   (dungeon.tiles[i][j].neighbours.se && dungeon.tiles[i][j].neighbours.se.type === 'wall') &&
    //   (dungeon.tiles[i][j].neighbours.sw && dungeon.tiles[i][j].neighbours.sw.type === 'wall')
    // ) { neighbours = 'allwalls' };

    //const alone = 
    if (neighbours === '') { neighbours = 'alone' };

    // Set to 55 if blank?
    t = reference.indices.walls.intersections[neighbours] ?? reference.indices.unknown;

  }
  else if (type === 'door') {
    const nWall = (dungeon.tiles[i][j].neighbours.n) ? (dungeon.tiles[i][j].neighbours.n && dungeon.tiles[i][j].neighbours.n.type === 'wall') : '';
    const sWall = (dungeon.tiles[i][j].neighbours.s) ? (dungeon.tiles[i][j].neighbours.s && dungeon.tiles[i][j].neighbours.s.type === 'wall') : '';
    //t = reference.indices.doors.horizontal; 
    if (nWall || sWall) {
      t = reference.indices.doors.vertical;
    } else {
      t = reference.indices.doors.horizontal;
    }
  }
  else //if (type==='floor')
  {
    t = reference.indices.floor.outer[4]; // 7;
  }
  return t;
}

//
// Create the monsters based on the dungeon
//
function generateMonsters(i, j, dungeon) {
  let type = dungeon.tiles[i][j].type;
  let t = reference.indices.unknown; // 55; // 
  if (type === 'wall') {
    let neighbours = '';
    neighbours += (dungeon.tiles[i][j].neighbours.n && dungeon.tiles[i][j].neighbours.n.type === 'wall') ? 'n' : '';
    neighbours += (dungeon.tiles[i][j].neighbours.e && dungeon.tiles[i][j].neighbours.e.type === 'wall') ? 'e' : '';
    neighbours += (dungeon.tiles[i][j].neighbours.s && dungeon.tiles[i][j].neighbours.s.type === 'wall') ? 's' : '';
    neighbours += (dungeon.tiles[i][j].neighbours.w && dungeon.tiles[i][j].neighbours.w.type === 'wall') ? 'w' : '';

    const wDoor = (dungeon.tiles[i][j].neighbours.w) ? (dungeon.tiles[i][j].neighbours.w && dungeon.tiles[i][j].neighbours.w.type === 'door') : '';
    const eDoor = (dungeon.tiles[i][j].neighbours.e) ? (dungeon.tiles[i][j].neighbours.e && dungeon.tiles[i][j].neighbours.e.type === 'door') : '';

    //const allwalls = 
    if (neighbours === 'nesw' &&
      (dungeon.tiles[i][j].neighbours.ne && dungeon.tiles[i][j].neighbours.ne.type === 'wall') &&
      (dungeon.tiles[i][j].neighbours.nw && dungeon.tiles[i][j].neighbours.nw.type === 'wall') &&
      (dungeon.tiles[i][j].neighbours.se && dungeon.tiles[i][j].neighbours.se.type === 'wall') &&
      (dungeon.tiles[i][j].neighbours.sw && dungeon.tiles[i][j].neighbours.sw.type === 'wall')
    ) { neighbours = 'allwalls' };

    //const alone = 
    if (neighbours === '') { neighbours = 'alone' };

    // Set to 55 if blank?
    t = reference.indices.walls.intersections[neighbours] ?? reference.indices.unknown;

  }
  else if (type === 'door') {
    const nWall = (dungeon.tiles[i][j].neighbours.n) ? (dungeon.tiles[i][j].neighbours.n && dungeon.tiles[i][j].neighbours.n.type === 'wall') : '';
    const sWall = (dungeon.tiles[i][j].neighbours.s) ? (dungeon.tiles[i][j].neighbours.s && dungeon.tiles[i][j].neighbours.s.type === 'wall') : '';
    //t = reference.indices.doors.horizontal; 
    if (nWall || sWall) {
      t = reference.indices.doors.vertical;
    } else {
      t = reference.indices.doors.horizontal;
    }
  }
  else //if (type==='floor')
  {
    t = reference.indices.floor.outer[4]; // 7;
  }
  return t;
}


