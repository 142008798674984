import Graphics from '../Graphics';
import { Mrpas } from 'mrpas';
import phaser from 'phaser';

const radius = 7;
const fogAlpha = 0.8;
const lightDropoff = [0.7, 0.6, 0.3, 0.1];
// Alpha to transition per MS given maximum distance between desired
// and actual alpha
const alphaPerMs = 0.004;
const updateTileAlpha = (desiredAlpha, delta, tile) => {
  // Update faster the further away we are from the desired value,
  // but restrict the lower bound so we don't get it slowing
  // down infinitley.
  var distance = Math.max(Math.abs(tile.alpha - desiredAlpha), 0.05);
  var updateFactor = alphaPerMs * delta * distance;
  if (tile.alpha > desiredAlpha) {
    tile.setAlpha(phaser.Math.MinSub(tile.alpha, updateFactor, desiredAlpha));
  } else if (tile.alpha < desiredAlpha) {
    tile.setAlpha(phaser.Math.MaxAdd(tile.alpha, updateFactor, desiredAlpha));
  }
};
export default class FOVLayer {
  constructor(map) {
    this.map = map;
    const utilTiles = map.tilemap.addTilesetImage('util');
    this.layer = map.tilemap
      // .createBlankDynamicLayer('Dark', utilTiles, 0, 0)
      .createBlankLayer('Dark', utilTiles, 0, 0)
      .fill(Graphics.default.util.indices.black);
    this.layer.setDepth(100);
    this.recalculate();
    this.lastPos = new phaser.Math.Vector2({ x: -1, y: -1 });
  }
  recalculate() {
    this.mrpas = new Mrpas(this.map.width, this.map.height, (x, y) => {
      return this.map.tiles[y] && !this.map.tiles[y][x].collides;
    });
  }
  update(pos, bounds, delta) {
    if (!this.lastPos.equals(pos)) {
      this.updateMRPAS(pos);
      this.lastPos = pos.clone();
    }
    for (var y = bounds.y; y < bounds.y + bounds.height; y++) {
      for (var x = bounds.x; x < bounds.x + bounds.width; x++) {
        if (y < 0 || y >= this.map.height || x < 0 || x >= this.map.width) {
          continue;
        }
        var desiredAlpha = this.map.tiles[y][x].desiredAlpha;
        var tile = this.layer.getTileAt(x, y);
        updateTileAlpha(desiredAlpha, delta, tile);
      }
    }
  }
  updateMRPAS(pos) {
    // TODO: performance?
    for (var _i = 0, _a = this.map.tiles; _i < _a.length; _i++) {
      var row = _a[_i];
      for (var _b = 0, row = row; _b < row.length; _b++) {
        var tile = row[_b];
        if (tile.seen) {
          tile.desiredAlpha = fogAlpha;
        }
      }
    }
    this.mrpas.compute(
      pos.x,
      pos.y,
      radius,
      (x, y) => {
        return this.map.tiles[y][x].seen;
      },
      (x, y) => {
        var distance = Math.floor(
          new phaser.Math.Vector2(x, y).distance(
            new phaser.Math.Vector2(pos.x, pos.y)
          )
        );
        var rolloffIdx = distance <= radius ? radius - distance : 0;
        var alpha =
          rolloffIdx < lightDropoff.length ? lightDropoff[rolloffIdx] : 0;
        this.map.tiles[y][x].desiredAlpha = alpha;
        this.map.tiles[y][x].seen = true;
      }
    );
  }
}
