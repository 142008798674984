import './static/css/index.scss';
import { Game, AUTO } from 'phaser';
import cmMenuController from './src/utils/cmUIController';
import Bootloader from './src/scenes/Bootloader';
import DungeonScene from './src/scenes/DungeonScene';
import GUI from './src/scenes/GUI';
import Joystick from './src/scenes/Joystick';
import Lobby from './src/scenes/Lobby';
import CharacterEdit from './src/scenes/CharacterEdit';
import cmEvents from './src/utils/cmEventController';
import RexUIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
import cmAudioController from './src/utils/cmAudioController';
import 'phaser/plugins/spine/dist/SpinePlugin';

const config = {
  name: 'RMS Crypto Coin',
  type: AUTO,
  width: window.innerWidth,
  height:
    window.innerHeight - document.querySelector('.cmPageHeader').style.height, // minus the header height
  scene: [Bootloader, Lobby, DungeonScene, GUI, Joystick, CharacterEdit],

  physics: {
    default: 'arcade',
    arcade: {
      gravity: { y: 0, x: 0 }
      // debug: true
    }
  },
  scale: {
    mode: Phaser.Scale.RESIZE
  },
  plugins: {
    scene: [
      {
        key: 'rexUI',
        plugin: RexUIPlugin,
        mapping: 'rexUI'
      },
      { key: 'SpinePlugin', plugin: window.SpinePlugin, mapping: 'spine' }
    ]
  }
};

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.cmMenuButton').forEach((n) => {
    n.addEventListener('click', (e) => {
      const name = e.target.name;
      switch (name) {
        case 'cmMenuBtn':
          cmMenuController.toggleDungeonList();
          break;
        case 'cmSettingsBtn':
          cmMenuController.toggleSettings();
          break;
        case 'cmChatBtn':
          cmMenuController.toggleChat();
          break;
        case 'cmMapBtn':
          cmMenuController.toggleMap();
          break;
        case 'cmCharacterBtn':
          cmMenuController.toggleCharacterView();
          break;
      }
      const evt = new CustomEvent(cmEvents.MENU_BUTTON_CLICKED, {
        detail: { name }
      });
      cmAudioController.click();
      document.dispatchEvent(evt);
    });
  });

  window.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  window.game = new Game(config);
});
