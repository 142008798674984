import { Scene } from 'phaser';
import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';

import Graphics from '../Graphics';
import Player from '../entities/Player';
import Beam from '../entities/Beam';
import cmStorage from '../utils/cmStorage';

const COLOR_PRIMARY = 0x4e342e;
const COLOR_LIGHT = 0x7b5e57;
const COLOR_DARK = 0x260e04;




export default class CharacterEdit extends Scene {
  rexUI = UIPlugin;
  player = null;
  background = null;
  printlabels = [];
  printvalues = [];


  constructor() {
    super({
      key: 'CharacterEdit'
    });
  }

  preload() {
    // this.backgroundColor = '#336699';
    this.load.image(
      Graphics.default.charEditor.background.name,
      Graphics.default.charEditor.background.file
    );
  }

  create() {

    // add player anims
    // Object.values(Graphics.default.player.animations).forEach((anim) => {
    //   this.anims.create({
    //     ...anim,
    //     frames: this.anims.generateFrameNumbers(
    //       Graphics.default.player.name,
    //       anim.frames
    //     )
    //   });
    // });
    // sprite = game.add.sprite(game., game.world.centerY, 'logo');

    let w = this.scale.canvasBounds.width;
    let h = this.scale.canvasBounds.height;

    this.background = this.add.sprite(
      w / 2,
      h / 2,
      Graphics.default.charEditor.background.name,
      0
    );
    // this.background.anchor.set(0.5);
    this.background.setDepth(0);

    // this.scale.fullScreenScaleMode = Phaser.ScaleManager.SHOW_ALL;
    // this.scale.scaleMode = Phaser.ScaleManager.SHOW_ALL;

    this.player = new Player(w * 3 / 4, h * 3 / 4, this);
    this.player.spine.scale = 1;

    console.log(this.player.spine)
    let skinId = 1;
    let skins = this.player.spine.getSkinList();
    this.player.spine.setSkinByName(skins[skinId]);


    console.log("Skin List");
    console.log(this.player.spine.getSkinList());
    console.log("Animation List");
    console.log(this.player.spine.getAnimationList());
    console.log("Slot List");
    console.log(this.player.spine.getSlotList());

    this.player.spine.getSlotList()

    console.log("Bone List");
    console.log(this.player.spine.getBoneList());

    // this.player.spine.setColor(0xFF0000, this.player.spine.getSlotList()[9]);

    let bone = this.player.spine.findBone("head")
    console.log("Head");



    console.log(bone);


    // bone.skeleton.slots[9].attachment.name = "Assassin/Assassin_head_1";;
    // bone.skeleton.slots[9].attachment.path = "Assassin/Assassin_head_1";;


    // this.player.spine.refresh();

    // skeleton.slots[9]
    // skeleton.slots[9].attachment.name
    // skeleton.slots[9].attachment.path
    // YoungBuck/YoungBuck_head_1
    // Assassin/Assassin_head_1

    // lor: Color
    // a: 1
    // b: 1
    // g: 1
    // r: 1
    // [[Prototype]]: Object
    // height: 543
    // name: "Assassin/Assassin_head_1"
    // offset: Float32Array(8)
    // 0: -77.85314178466797
    // 1: 102.14697265625
    // 2: 262.5795593261719
    // 3: 142.25909423828125
    // 4: 297.0121154785156
    // 5: -149.9707489013672
    // 6: -43.42060089111328
    // 7: -190.0828857421875
    // buffer: 
    // ArrayBuffer(32)
    // byteLength: 32
    // byteOffset: 0
    // length: 8
    // Symbol(Symbol.toStringTag): (...)
    // [[Prototype]]: TypedArray
    // path: "Assassin/Assassin_head_1"
    // region: TextureAtlasRegion
    // degrees: 0
    // height: 113
    // index: -1
    // name: "Buck/BuckMatthews_head_1"
    // offsetX: 40
    // offsetY: 16
    // originalHeight: 179
    // originalWidth: 179
    // page: TextureAtlasPage
    // height: 1024
    // magFilter: 9729
    // minFilter: 9729
    // name: "heroes.png"
    // texture: GLTexture {_image: img, texture: WebGLTexture, boundUnit: 0, useMipMaps: false, context: ManagedWebGLRenderingContext}
    // uWrap: 33071
    // vWrap: 33071
    // width: 1024
    // [[Prototype]]: Object
    // renderObject: TextureAtlasRegion {u: 0.8994140625, v: 0.6455078125, u2: 0.994140625, v2: 0.755859375, width: 97, …}
    // rotate: false
    // texture: GLTexture {_image: img, texture: WebGLTexture, boundUnit: 0, useMipMaps: false, context: ManagedWebGLRenderingContext}
    // u: 0.8994140625
    // u2: 0.994140625
    // v: 0.6455078125
    // v2: 0.755859375
    // width: 97
    // x: 921
    // y: 661
    // [[Prototype]]: TextureRegion
    // rotation: 276.72
    // scaleX: 1
    // scaleY: 1
    // tempColor: Color {r: 1, g: 1, b: 1, a: 1}
    // uvs: Float32Array(8) [0.8994140625, 0.755859375, 0.8994140625, 0.6455078125, 0.994140625, 0.6455078125, 0.994140625, 0.755859375, buffer: ArrayBuffer(32), byteLength: 32, byteOffset: 0, length: 8]
    // width: 543
    // x: 161.15
    // y: -20.

    // console.log(bone.skeleton.slots[9].attachment.name);
    // console.log(bone.skeleton.slots[9].attachment.path);


    // this.player.spine.setAttachment('head', 'head_4_injured')
    // let att = this.player.spine.getAttachmentByName('head', 'head_4_injured')
    // let att = this.player.spine.getAttachment(9, 'head_1')
    // console.log("Attachement");
    // att.setColor(0x00FF00)   <- set color for the entire body
    // att.color.b = 0xFF;   <- set color for an attachement part
    // att.name = "Assassin/Assassin_head_1";
    // att.path = "Assassin/Assassin_head_1";
    // console.log(att);

    // this.player.spine.refresh();

    var print0 = this.add.text(0, 0, '');
    var content = `Customized Miner.`;

    this.rexUI.add
      .sizer({
        x: 250,
        y: 100,
        width: 480,
        // height: 100,
        orientation: 'x',

        space: { left: 10, right: 10, top: 10, bottom: 25, item: 10 }
      })
      .add(this.rexUI.add.roundRectangle(0, 0, 0, 0, 20, COLOR_LIGHT), {
        proportion: 0,
        align: 'bottom'
      })
      .add(this.rexUI.wrapExpandText(this.add.text(0, 0, content)), {
        proportion: 1,
        align: 'center',
        expand: true
      })
      .add(this.rexUI.add.roundRectangle(0, 0, 0, 0, 20, COLOR_LIGHT), {
        proportion: 0,
        align: 'bottom'
      })
      .layout()
      .drawBounds(this.add.graphics(), 0xff0000);

    const SLIDER_STARTY = 200;
    const SLIDER_OFFSET = 40;

    let configSliders = this.player.configurations;

    for (let i = 0; i < configSliders.length; i++) {
      const label = configSliders[i].label;
      this.printlabels[i] = this.add.text(
        10,
        SLIDER_STARTY + SLIDER_OFFSET * i,
        label
      );
      this.printvalues[i] = this.add.text(
        510,
        SLIDER_STARTY + SLIDER_OFFSET * i,
        ''
      );
      let _this = this;
      this.rexUI.add
        .slider({
          x: 300,
          y: SLIDER_STARTY + 10 + SLIDER_OFFSET * i,
          width: 300,
          height: 30,
          orientation: 'x',
          value: parseFloat(cmStorage.getValueForKey(label, 0) / configSliders[i].maxVal),
          track: this.rexUI.add.roundRectangle(0, 0, 0, 0, 10, COLOR_DARK),
          indicator: this.rexUI.add.roundRectangle(
            0,
            0,
            0,
            0,
            10,
            COLOR_PRIMARY
          ),
          thumb: this.rexUI.add.roundRectangle(0, 0, 0, 0, 10, COLOR_PRIMARY),
          input: 'click', // 'drag'|'click'
          easeValue: { duration: 100 },
          valuechangeCallback: function (value) {
            // console.log(label, Math.floor(value * 100));
            // let _val = (value < 1) ? Math.floor(value * _this.configSliders[i].maxVal) : value;
            let _val = Math.floor(value * configSliders[i].maxVal);

            cmStorage.setValueForKey(label, _val);

            _this.printvalues[i].text = configSliders[i].func(
              _this,
              _this.player,
              _val
            );
          }
        })
        .layout();
    }

    var buttons = this.rexUI.add
      .buttons({
        x: 800,
        y: 100,
        name: 'btn0',
        // anchor: undefined,
        width: 100,
        height: 50,

        orientation: 0,

        // Elements
        // background: backgroundGameObject,
        background: this.rexUI.add.roundRectangle(
          0,
          0,
          0,
          0,
          10,
          COLOR_PRIMARY
        ),
        // buttons: [
        //   buttonGameObject,
        //   buttonGameObject,
        //   // ...
        // ],
        expand: false,
        align: undefined,
        click: {
          mode: 'pointerdown',
          clickInterval: 100
        },

        // space: 0,
        // space: { left: 0, right:0, top:0, bottom:0, item:0 },

        // name: '',
        // draggable: false,
        // sizerEvents: false,
        eventEmitter: this,
        // groupName: undefined,

        // type: undefined,
        // : undefined,
        // setValueCallbackScope: undefined
        setValueCallback: function (button, value, previousValue) {
          console.log('Pressed Button');
        }
      })
      .add(this.rexUI.wrapExpandText(this.add.text(100, 50, 'Start')), {
        proportion: 1,
        align: 'center',
        expand: true
      })
      .layout()
      .drawBounds(this.add.graphics(), 0x00ff00);

    // let but = this.plugins.get('btn0');
    // console.log(buttons)

    // buttons.on('click', function (button, gameObject) {
    //   console.log("clikced")
    //   scene.print.text += 'click ' + gameObject.name + '\n';
    // });
  }

  update() { }
}
