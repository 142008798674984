import { Scene } from 'phaser';
import cmAudioController from '../utils/cmAudioController';
import cmGestureManager from '../utils/cmGestureController';
import cmUIController from '../utils/cmUIController';
import cmEvents from '../utils/cmEventController';

export default class Lobby extends Scene {
  container = null;
  constructor() {
    super({ key: 'Lobby' });
  }
  create() {
    // audio controller
    cmAudioController.init(this);
    cmAudioController.playTrack('game');

    cmUIController.hydrate();

    cmUIController.openDungeonList((dungeonUrl) => {
      this.scene.start('DungeonScene', { dungeonUrl });
      cmUIController.closeDungeonList();
    });

    // TOGGLE on / off character edit
    if (!document.__cmHasCharacterEditToggleListener) {
      document.__cmHasCharacterEditToggleListener = true;
      cmEvents.listen(cmEvents.TOGGLE_CHARACTER_VIEW, () => {
        if (!this.characterEdit) {
          this.scene.run('CharacterEdit');
          this.characterEdit = this.scene.get('CharacterEdit');
        } else {
          this.scene.stop('CharacterEdit');
          this.characterEdit = null;
        }
      });
      cmEvents.listen(cmEvents.OPEN_CHARACTER_VIEW, () => {
        if (!this.characterEdit) {
          this.scene.run('CharacterEdit');
          this.characterEdit = this.scene.get('CharacterEdit');
        }
      });
      cmEvents.listen(cmEvents.CLOSE_CHARACTER_VIEW, () => {
        if (this.characterEdit) {
          this.scene.stop('CharacterEdit');
          this.characterEdit = null;
        }
      });
    }
  }
  update() {
    cmGestureManager.update(this.input);
  }
}
