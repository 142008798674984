import { Scene } from 'phaser';
import Graphics from '../Graphics';
import cmState from '../utils/cmState';
import { getScaleFactor } from '../utils/utils';
import cmEvents from '../utils/cmEventController';
import cmGestureManager from '../utils/cmGestureController';
import cmUIController from '../utils/cmUIController';

class Meter {
  base = null;
  stem = null;

  constructor(scene, x, y, color) {
    let w = Graphics.default.meter.stem.width;
    let h = Graphics.default.meter.stem.height;
    let radius = Graphics.default.meter.base.radius;

    this.base = scene.add.circle(
      x,
      y,
      radius,
      color,
      Graphics.default.meter.alpha
    );
    this.stem = scene.add.rectangle(
      x,
      y,
      w,
      h,
      color,
      Graphics.default.meter.alpha
    );
    this.stem.setOrigin(0.5, 1);
    this.update(0);
  }

  update(wellLevel) {
    let fullness = wellLevel / Graphics.default.meter.maxload;
    if (fullness > 1) fullness = 1;
    this.stem.height = Math.floor(
      Graphics.default.meter.stem.height * fullness
    );
  }
}

export default class GUI extends Scene {
  debugMode = cmState.settings.isDebugMode;
  attackButton = null;
  colorBall = null;
  upperColorBall = null;
  lowerColorBall = null;
  attackIsPressed = false;
  fullscreenButton = null;
  colorBallContainer = null;

  colorMeters = [];

  constructor() {
    super({ key: 'GUI' });
  }

  create() {
    // allows more touch inputs at once.
    this.addColorCollectors();

    if (window.isMobile) {
      this.input.addPointer();
      this.addFullscreenButton();
      this.addColorChangeButton();
      this.scale.on('resize', this.onResize, this);
      this.onResize({ width: window.innerWidth, height: window.innerHeight });
    }

    // this.keys = this.input.keyboard.addKeys({
    //   button_down: Phaser.Input.Keyboard.KeyCodes.O
    // dcolor: phaser.Input.Keyboard.KeyCodes.O,
    // ucolor: phaser.Input.Keyboard.KeyCodes.P,
    // });

    // A ~ Z
    // F1 ~ F12
    // BACKSPACE
    // TAB
    // ENTER
    // SHIFT
    // CTRL. ALT
    // PAUSE
    // CAPS_LOCK
    // ESC
    // SPACE
    // PAGE_UP, PAGE_DOWN
    // END, HOME
    // LEFT, UP, RIGHT,DOWN
    // PRINT_SCREEN
    // INSERT, DELETE
    // ZERO, ONE, TWO, THREE, FOUR, FIVE, SIX, SEVEN, EIGHT, NINE
    // NUMPAD_ZERO, NUMPAD_ONE, NUMPAD_TWO, NUMPAD_THREE, NUMPAD_FOUR, NUMPAD_FIVE, NUMPAD_SIX, NUMPAD_SEVEN, NUMPAD_EIGHT, NUMPAD_NINE, NUMPAD_ADD, NUMPAD_SUBTRACT

    // Increment Color
    this.input.keyboard.on('keydown-SPACE', function (event) {
      const beam = game.scene.getScene('DungeonScene').beam;
      const color = beam._incColor();
      this.colorBall && this.colorBall.setTint(color);
    });
    // Decrement Color
    this.input.keyboard.on('keydown-CTRL', function (event) {
      const beam = game.scene.getScene('DungeonScene').beam;
      const color = beam._decColor();
      this.colorBall && this.colorBall.setTint(color);
    });

    if (this.debugMode) {
      // console log the map
      this.input.keyboard.on('keydown-ENTER', function (event) {
        const mapinfo = game.scene.getScene('DungeonScene').mapData;
        let logging = Graphics.default.logging;
        // console.log(mapinfo)

        console.log('RESPONSE Version: ', mapinfo.version);
        console.log('  Domain: ', mapinfo.seed);
        console.log(mapinfo.rooms);
        console.log('\u263A - Player Start');
        console.log('\u24C2 - Monster');
        console.log(mapinfo.monsters);
        console.log('$ - Treasure');
        console.log(mapinfo.treasure);

        let i = 0;
        for (i = 0; i < mapinfo.monsters.length; ++i) {
          let x = mapinfo.monsters[i].position.x;
          let y = mapinfo.monsters[i].position.y;
          mapinfo.tiles[x][y] = 193;
        }

        for (i = 0; i < mapinfo.tiles.length; ++i) {
          let str = '';
          for (let j = 0; j < mapinfo.tiles[i].length; ++j) {
            if (
              mapinfo.startPosition.x === j &&
              mapinfo.startPosition.y === i
            ) {
              str += '\u263A';
            } else {
              str += logging[mapinfo.tiles[j][i]];
            }
          }
          console.log(str);
        }
      });

      // Turn on / off the lights
      this.input.keyboard.on('keydown-F1', function (event) {
        const fov = game.scene.getScene('DungeonScene').fov;
        fov.layer.setVisible(!fov.layer.visible);
      });

      // Turn on / off the bricks
      this.input.keyboard.on('keydown-F2', function (event) {
        console.log('F2');
        const block = game.scene
          .getScene('DungeonScene')
          .tilemap.getLayer('Blocks');
        console.log(block);
      });

      // Turn on / off the collisions
      this.input.keyboard.on('keydown-F3', function (event) {
        console.log('F3');
        const scene = game.scene.getScene('DungeonScene').toggleColliders();
        console.log(scene);
      });

      // Turn on / off the debugging drawings
      this.input.keyboard.on('keydown-ESC', function (event) {
        const scene = game.scene.getScene('DungeonScene');
        scene.physics.world.drawDebug = !scene.physics.world.drawDebug;
        if (!scene.physics.world.debugGraphic) {
          scene.physics.world.createDebugGraphic();
        }
        scene.physics.world.debugGraphic.clear();
        scene.roomDebugGraphics.setVisible(scene.physics.world.drawDebug);
      });

      // Increase speed
      this.input.keyboard.on('keydown-PAGE_UP', function (event) {
        const beam = game.scene.getScene('DungeonScene').beam;
        beam.incrSpeed();
      });
      // Decrease speed
      this.input.keyboard.on('keydown-PAGE_DOWN', function (event) {
        const beam = game.scene.getScene('DungeonScene').beam;
        beam.decrSpeed();
      });
      // Increase length
      this.input.keyboard.on('keydown-INSERT', function (event) {
        const beam = game.scene.getScene('DungeonScene').beam;
        beam.incrRange();
      });
      // Decrease length
      this.input.keyboard.on('keydown-DELETE', function (event) {
        const beam = game.scene.getScene('DungeonScene').beam;
        beam.decrRange();
      });
      // Increase width
      this.input.keyboard.on('keydown-HOME', function (event) {
        const beam = game.scene.getScene('DungeonScene').beam;
        beam.incrSpread();
      });
      // Decrease width
      this.input.keyboard.on('keydown-END', function (event) {
        const beam = game.scene.getScene('DungeonScene').beam;
        beam.decrSpread();
      });
    }

    this.addGestureListeners();
  }
  update() {
    cmGestureManager.update(this.input);
  }

  onResize(size) {
    const { width, height } = size;
    const scale = getScaleFactor();

    // update full screen button
    if (this.fullscreenButton) {
      this.fullscreenButton.setPosition(width, 0);
      this.fullscreenButton.setScale(Math.min(scale, 0.3));

      // update color change button
      this.colorBallContainer.setPosition(0, height);
      // this.colorBallContainer.setPosition(500, 400);
      this.colorBallContainer.setScale(scale + 0.7);
    }
    this.addColorCollectors();
  }

  addLightingButtons() {
    // up down buttons for lighting
    const y = window.innerHeight * 0.73;
    this.add
      .image(window.innerWidth * 0.2, y, 'gui', 'cc_char_options_arrow_right')
      .setScale(getScaleFactor() + 1)
      .setInteractive()
      .setAngle(-90)
      .on('pointerdown', () => {
        this.events.emit(cmEvents.BUTTON_DOWN_PRESSED);
      });
    this.add
      .image(
        window.innerWidth * 0.2,
        y + 111,
        'gui',
        'cc_char_options_arrow_right'
      )
      .setScale(getScaleFactor() + 1)
      .setInteractive()
      .setAngle(90)
      .on('pointerdown', () => {
        this.events.emit(cmEvents.BUTTON_UP_PRESSED);
      });
  }

  addGestureListeners() {
    // this.tilemap.setInteractive();
    // this.input.on('pointerdown', (pointer) => {
    //   console.log(pointer);
    // });
    // this.on('pointerdown', () => {

    // });

    // this.input.addPointer(3);

    this.input.on('pointerup', (pointer) => {
      console.log(pointer);
      // if (!this.controller.isDown || this.pointerId != id) return;
      // this.controller.isDown = false;
      // this.up = this.down = this.left = this.right = false;
      // this.controller.setPosition(
      //   this.controller.startX,
      //   this.controller.startY
      // );
      // this.controller.isDown = false;
      // this.glow.setVisible(false);
      // this.up = this.down = this.left = this.right = false;
      // this.tweens.add({
      //   targets: this.controller,
      //   x: this.controller.startX,
      //   y: this.controller.startY,
      //   ease: 'Back.easeOut',
      //   duration: 250
      //   // repeat: -1
      // });
    });
    this.input.on('pointermove', ({ position, id }) => {
      // if (!this.controller.isDown || this.pointerId !== id) return;
      // // get amount moved
      // const difference = new Phaser.Math.Vector2(
      //   position.x,
      //   position.y
      // ).subtract(
      //   new Phaser.Math.Vector2(this.controllerFrame.x, this.controllerFrame.y)
      // );
      // // clamp the difference
      // const pos = new Phaser.Math.Vector2(
      //   Phaser.Math.Clamp(difference.x, -tollerance, tollerance),
      //   Phaser.Math.Clamp(difference.y, -tollerance, tollerance)
      // );
      // const newPos = new Phaser.Math.Vector2(
      //   this.controllerFrame.x,
      //   this.controllerFrame.y
      // ).add(pos);
      // this.controller.setPosition(newPos.x, newPos.y);
      // if (pos.x > play) {
      //   this.right = true;
      // } else if (pos.x < -play) {
      //   this.left = true;
      // } else {
      //   this.right = this.left = false;
      // }
      // if (pos.y > play) {
      //   this.down = true;
      // } else if (pos.y < -play) {
      //   this.up = true;
      // } else {
      //   this.up = this.down = false;
      // }
    });
  }

  addFullscreenButton() {
    this.fullscreenButton = this.add
      .image(0, 0, 'gui', 'fullscreen')
      .setInteractive()
      .setOrigin(1, 0)
      .on('pointerdown', () => {
        if (this.scale.isFullscreen) {
          this.scale.stopFullscreen();
        } else {
          this.scale.startFullscreen();
        }
      });
  }

  addColorChangeButton() {
    const colorBallContainer = this.add.container();
    const beam = game.scene.getScene('DungeonScene').beam;
    colorBallContainer.add(
      this.add
        .sprite(0, 0, 'gui', 'ab1_globe_empty_right')
        .setOrigin(0.22, 0.82)
        .setInteractive()
        .on('pointerdown', (pointer, x, y, stopPropagation) => {
          this.colorBall.setTint(beam._incColor());
        })
    );
    colorBallContainer.add(
      (this.colorBall = this.add
        .image(0, 0, 'gui', 'color-ball-white')
        .setTint(beam.getColor())
        .setOrigin(0, 1))
    );
    colorBallContainer.add(
      this.add.image(0, 0, 'gui', 'ab1_globe_overlay_bl').setOrigin(0, 1)
    );
    colorBallContainer.add(
      this.add.image(0, 0, 'gui', 'ab1_globe_overlay_effects').setOrigin(0, 1)
    );

    this.colorBallContainer = colorBallContainer;
  }

  addColorCollectors() {
    let n = Graphics.default.colorList.length;
    for (let i = 0; i < n; i++) {
      this.colorMeters.push(
        cmUIController.makeColorOrb(Graphics.default.colorList[i])
      );
    }
  }

  updateColorCollectors(color, level) {
    const orb = this.colorMeters[color];
    orb.update();
  }
}
