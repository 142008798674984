const TILES_WIDTH = 16;
const TILES_HEIGHT = 16;

var environment = {
  name: '../../static/Env16x16-extruded',
  width: TILES_WIDTH,
  height: TILES_HEIGHT,
  worldTileHeight: 31, // this value must be ODD
  worldTileWidth: 31, // this value must be ODD
  margin: 1,
  spacing: 2,
  // file: RogueEnvironment16x16_extruded_png_1.default,
  indices: {
    floor: {
      outer: [0x05, 0x05, 0x05, 0x15, 0x07, 0x17],
      outerCorridor: [0x0d, 0x0d, 0x0d, 0x1d, 0x0f, 0x1f]
    },
    block: 0x17,
    treasure: {
      closed: 0xc0,
      destroyed: 0xc5,
      open: [0xc1, 0xc2, 0xc3, 0xc4, 0xc5, 0xc6]
    },
    doors: {
      horizontal: 0x81,
      vertical: 0x90,
      destroyed: 0x35
    },
    stairs: {
      down: 0x76,
      up: 0x75
    },
    walls: {
      alone: 0x14,
      intersections: {
        alone: 0x14,
        allwall: 0xfe,
        es: 0x00,
        nesw: 0x01,
        ew: 0x02,
        sw: 0x03,
        nes: 0x10,
        w: 0x11,
        e: 0x12,
        nsw: 0x13,
        ns: 0x20,
        s: 0x21,
        esw: 0x22,
        ne: 0x30,
        new: 0x31,
        n: 0x32,
        nw: 0x33,
        e_door: 0x65,
        w_door: 0x67
      }
    }
  }
};

var colorList = [
  //0xeeeeee, 0xee0000, 0x00ee00, 0x0000ee, 0xeeee00, 0xee00ee, 0x00eeee
  0xeeeeee, 0xee0000, 0x00ee00
];

var meter = {
  offset: 50,
  maxload: 100,
  alpha: 0.75,

  base: {
    radius: 10
  },
  stem: {
    width: 5,
    height: 100
  }
};

var blocks = {
  name: '../../static/Block16x16',
  width: TILES_WIDTH,
  height: TILES_HEIGHT,
  margin: 1,
  spacing: 2,
  indices: {
    colors: [0x00, 0x10, 0x20, 0x30, 0x40, 0x50, 0x60, 0x70, 0x80, 0x90]
  }
};

var beam = {
  name: 'Beam',
  headPlacementYoffset: 20,
  sizeAdjust: TILES_WIDTH,
  originXoffset: 0.5,
  originYoffset: 0,
  margin: 1,
  spacing: 2,
  colors: colorList,
  alpha: 0.75,
  alphaflutter: 0.25,
  updateRate: 50, //ms
  speedIncrement: 0.1,
  maxrange: 10,
  initial: {
    speed: 0.25, // degrees per update
    range: 4, // in tiles
    spread: 10,
    angle: 135,
    color: 0xee0000
  }
};

var player = {
  name: 'player',
  file: '../../static/player.png',
  width: 128,
  height: 128,
  visible: false,
  spine: {
    scale: 0.03
  },

  // 0: "aim"
  // 1: "death"
  // 2: "hoverboard"
  // 3: "idle"
  // 4: "jump"
  // 5: "portal"
  // 6: "run"
  // 7: "shoot"
  // 8: "walk"
  animations: {
    idle: {
      // key: 'playerIdle',
      key: 'idle',
      // frames: { start: 0x01, end: 0x07 },
      // frameRate: 6,
      repeat: -1
    },
    idleBack: {
      // key: 'playerIdleBack',
      key: 'idle',
      // frames: { start: 0x0a, end: 0x11 },
      // frameRate: 6,
      repeat: -1
    },
    walk: {
      // key: 'playerWalk',
      key: 'walk',
      // frames: { start: 0x14, end: 0x19 },
      // frameRate: 10,
      repeat: -1
    },
    walkBack: {
      // key: 'playerWalkBack',
      key: 'walk',
      // frames: { start: 0x1e, end: 0x23 },
      // frameRate: 10,
      repeat: -1
    },
    // Ideally attacks should be five frames at 30fps to
    // align with the attack duration of 165ms
    slash: {
      // key: 'playerSlash',
      key: 'run'
      // frames: { frames: [0x1a, 0x1a, 0x1a, 0x1b, 0x1c] },
      // frameRate: 30
    },
    slashUp: {
      // key: 'playerSlashUp',
      key: 'run'
      // frames: { frames: [0x2e, 0x2e, 0x2e, 0x2f, 0x30] },
      // frameRate: 30
    },
    slashDown: {
      // key: 'playerSlashDown',
      key: 'run'
      // frames: { frames: [0x24, 0x24, 0x24, 0x25, 0x26] },
      // frameRate: 30
    },
    stagger: {
      // key: 'playerStagger',
      key: 'death'
      // frames: { frames: [0x38, 0x38, 0x39, 0x3a] },
      // frameRate: 30
    }
  }
};

var monster = {
  name: '../../static/01Monster32x32',
  width: 32,
  height: 32,
  color: 2,
  speed: 20,
  // file: Roguemonster32x32_png_1.default,
  animations: {
    idle: {
      key: 'monsterIdle',
      frames: { start: 0x00, end: 0x05 },
      frameRate: 6,
      repeat: -1
    },
    move: {
      key: 'monsterMove',
      frames: { start: 0x08, end: 0x0e },
      frameRate: 8,
      repeat: -1
    },
    death: {
      key: 'monsterDeath',
      frames: { start: 0x20, end: 0x26 },
      frameRate: 16,
      hideOnComplete: true
    }
  }
};

var items = {
  name: '../../static/Items16x16',
  width: 16,
  height: 16
  // file: RogueItems16x16_png_1.default
};

var particles = {
  name: 'particle',
  file: '../../static/blue.png'
};

var util = {
  name: 'util',
  width: 16,
  height: 16,
  // file: Util_png_1.default,
  file: '../../static/Util.png',
  indices: {
    black: 0x00
  }
};

var charEditor = {
  background: {
    name: 'panel',
    file: '../../static/panel.png'
  }
};

const logging = [
  /* 000 */ '\u250F',
  '\u254B',
  '\u2501',
  '\u2501',
  '\u2503',
  '\u2024',
  '\u2024',
  '\u2024',
  '\u250F',
  '\u254B',
  '\u2501',
  '\u2501',
  '\u2503',
  '\u2024',
  '\u2024',
  '\u2024',
  /* 016 */ '\u2523',
  '\u251B',
  '\u257C',
  '\u252B',
  '\u2578',
  '\u2024',
  '\u2024',
  '\u2024',
  '\u2523',
  '\u251B',
  '\u257C',
  '\u252B',
  '\u2578',
  '\u2024',
  '\u2024',
  '\u2024',
  /* 032 */ '\u2503',
  '\u257D',
  '\u2533',
  '\u254F',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2503',
  '\u257D',
  '\u2533',
  '\u254F',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  /* 048 */ '\u2517',
  '\u253B',
  '\u257F',
  '\u251B',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2517',
  '\u253B',
  '\u257F',
  '\u251B',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  /* 064 */ '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  /* 080 */ '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  /* 096 */ '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2510',
  '\u25A0',
  '\u250C',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  /* 112 */ '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  /* 128 */ '\u258C',
  '\u25AF',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  /* 144 */ '\u258C',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  /* 160 */ '\u258C',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  /* 176 */ '\u258C',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  /* 192 */ '$',
  'm',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573',
  '\u2573'
];

exports.default = {
  environment: environment,
  blocks: blocks,
  player: player,
  // monster: monster,
  items: items,
  util: util,
  beam: beam,
  particles: particles,
  colorList: colorList,
  meter: meter,
  charEditor: charEditor,
  logging: logging
};
