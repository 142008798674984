import phaser from "phaser";
import Graphics from "../Graphics";

export default class Treasure {
    constructor(x, y, scene) {
        this.combination = [1, 2, 1];//Graphics.default.monster.color;
        this.comboIndex = 0;//Graphics.default.monster.color;
    }

    kill() {
    };
}