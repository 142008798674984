import { Scene } from 'phaser';
import Graphics from '../Graphics';
import cmUIController from '../utils/cmUIController';

export default class Bootloader extends Scene {
  constructor() {
    super({ key: 'Bootloader' });
  }
  preload() {
    this.load.audio('game', 'static/audio/game.wav');
    this.load.audio('click', 'static/audio/click.wav');
    this.load.audio('coin', 'static/audio/coin.wav');
    this.load.audio('correct', 'static/audio/correct.wav');

    this.load.multiatlas('gui', 'static/gui.json', 'static/');
    this.load.image(
      Graphics.default.environment.name,
      Graphics.default.environment.file
    );
    this.load.image(Graphics.default.player.name, Graphics.default.player.file);
    this.load.image(
      Graphics.default.particles.name,
      Graphics.default.particles.file
    );
    this.load.image(Graphics.default.blocks.name, Graphics.default.blocks.file);
    this.load.image(Graphics.default.util.name, Graphics.default.util.file);
    // this.load.spritesheet(
    //   Graphics.default.player.name,
    //   Graphics.default.player.file,
    //   {
    //     frameHeight: Graphics.default.player.height,
    //     frameWidth: Graphics.default.player.width
    //   }
    // );
    // this.load.spritesheet(
    //   Graphics.default.head.name,
    //   Graphics.default.head.file,
    //   {
    //     frameHeight: Graphics.default.head.height,
    //     frameWidth: Graphics.default.head.width
    //   }
    // );

    this.load.setPath('static/demos/');
    this.load.spine(
      'monsters',
      'demos.json',
      ['atlas1.atlas', 'atlas2.atlas'],
      true
    );
    // this.load.spine('hero', 'heroes.json', 'heroes.atlas');
    this.load.spine('hero', 'testhero.json', 'heroes.atlas');
    // this.load.setPath('static/alien/');
    // this.load.spine('set1', 'alien-ess.json', ['alien.atlas'], true);

    // this.load.spritesheet(
    //   Graphics.default.monster.name,
    //   Graphics.default.monster.file,
    //   {
    //     frameHeight: Graphics.default.monster.height,
    //     frameWidth: Graphics.default.monster.width
    //   }
    // );
  }

  create() {
    cmUIController.addClickListenerToPreloader();
    // for quick skipping the preload view

    document
      .querySelector('.cmPreloaderView')
      .dispatchEvent(new CustomEvent('click'));

    // this.scene.start('CharacterEdit')
    this.scene.start('Lobby');
    // this.scene.start('DungeonScene');
  }
}
